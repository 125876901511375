import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IRecognition, IFileImport, IRecognitionsSettings } from 'types/income';
import { FrequencyUnit } from 'types/general';
import { exportXlsxConfig, getFormData } from 'utils/general';
import { Translations } from 'types/Common';
import {
  ExpirationNotificationMethod,
  RecognitionLimit,
  RecognitionPoint,
  RecognitionsFilters,
} from './recognitions.types';

interface GetRecognitions {
  filters: Partial<RecognitionsFilters>;
}

interface ExportXls {
  filters: Partial<RecognitionsFilters>;
}

interface ApproveRecognition {
  recognitionId: string;
  approval: boolean;
}

interface EditRecognition {
  recognitionId: string;
  points: number;
  comment: string;
}

interface DeleteRecognition {
  recognitionId: string;
}

interface ImportRecognitions {
  file: File;
}

interface UpdateRecognitionsSettings {
  pointRewardPerRecognition: number;
  pointName: RecognitionPoint;
  currencyToPoint: number;
  useMoney: boolean;
  limit: RecognitionLimit;
  limitValue: number;
  limitPeriod: FrequencyUnit | 'none';
  expirationEnabled: boolean;
  expirationDay: number;
  expirationMonth: number;
  expirationNotificationInDays: number;
  expirationNotification: ExpirationNotificationMethod[];
  showPointsInFeed: boolean;
  translations: Translations<{ description: string }>;
}

export const loaders = {
  getRecognitions: ({ filters }: GetRecognitions) => {
    return api<TableDataResponse<IRecognition>>({
      url: Endpoints.clientAdmin.recognitions.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          type: filters.type,
          achievementId: filters.achievements,
          dateFrom: filters.date?.from,
          dateTo: filters.date?.to,
          employeeName: filters.employee,
        },
      },
    });
  },
  getRecognitionsSettings: () => {
    return api<IRecognitionsSettings>({
      url: Endpoints.clientAdmin.recognitions.settings,
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.export.xls,
      params: {
        filter: {
          type: filters.type,
          achievementId: filters.achievements,
          dateFrom: filters.date?.from,
          dateTo: filters.date?.to,
          employeeName: filters.employee,
        },
      },
      ...exportXlsxConfig,
    });
  },
  downloadTemplate: () => {
    return api({
      url: Endpoints.clientAdmin.recognitions.export.template,
      ...exportXlsxConfig,
    });
  },
  approveRecognition: ({ recognitionId, approval }: ApproveRecognition) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.approve.byId.replace(
        ':recognitionId',
        recognitionId,
      ),
      method: 'put',
      data: {
        approval,
      },
    });
  },
  editRecognition: ({ recognitionId, points, comment }: EditRecognition) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.byId.replace(
        ':recognitionId',
        recognitionId,
      ),
      method: 'patch',
      data: {
        points,
        comment,
      },
    });
  },
  deleteRecognition: ({ recognitionId }: DeleteRecognition) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.byId.replace(
        ':recognitionId',
        recognitionId,
      ),
      method: 'delete',
    });
  },
  importRecognitions: ({ file }: ImportRecognitions) => {
    return api<IFileImport>({
      url: Endpoints.clientAdmin.recognitions.import,
      method: 'post',
      data: getFormData([{ field: 'file', value: file }]),
    });
  },
  updateRecognitionsSettings: (data: UpdateRecognitionsSettings) => {
    return api({
      url: Endpoints.clientAdmin.recognitions.settings,
      method: 'patch',
      data: {
        pointName: data.pointName,
        useMoney: data.useMoney,
        currencyToPoint: data.currencyToPoint,
        pointRewardPerRecognition: data.pointRewardPerRecognition,
        limit: data.limit,
        limitValue: data.limitValue,
        limitPeriod: data.limitPeriod,
        hasPeriodicExpiration: data.expirationEnabled,
        expirationDay: data.expirationDay,
        expirationMonth: data.expirationMonth,
        expirationNotificationDays: data.expirationNotificationInDays,
        expirationNotification: data.expirationNotification,
        showPointsInFeed: data.showPointsInFeed,
        instructions: data.translations.map((trans) => ({
          language: trans.language,
          content: trans.description,
        })),
      },
    });
  },
  triggerExpiration: () => {
    return api({
      url: Endpoints.clientAdmin.recognitions.expiration,
      method: 'post',
    });
  },
};
