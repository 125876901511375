import { AppBar, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { APP } from 'config';
import { LogoColored } from 'melp-design/icons';
import { Colors } from 'melp-design/style';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: `1px solid ${Colors.elementsGrey}`,
    alignItems: 'center',
  },
  headerContent: {
    width: '100%',
    // Max width takes padding into account
    maxWidth: APP.layout.container.maxWidth + 2 * 24,
    height: 70,
  },
  logoContainer: {
    height: 28,
    marginRight: 40,
  },
  logo: {
    height: 28,
    width: 'auto',
  },
  menuItems: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    flexGrow: 1,
  },
  verticallyCentered: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface Props {
  /**
   * URL that leads to the homepage for current user
   */
  homePageURL?: string;
  /**
   * Navigation menu
   */
  children?: ReactNode;
  /**
   * Global search element
   */
  search?: ReactNode;
  /**
   * User info element
   */
  user?: ReactNode;
}

const Header = ({ homePageURL = '/', ...props }: Props) => {
  const classes = useStyles();

  return (
    <AppBar position="static" color="default" className={classes.root}>
      <Toolbar className={classes.headerContent}>
        <div className={classes.logoContainer}>
          <NavLink to={homePageURL}>
            <LogoColored className={classes.logo} />
          </NavLink>
        </div>
        <div className={classes.menuItems}>{props.children}</div>
        <div className={classes.verticallyCentered}>{props.search}</div>
        <div className={classes.verticallyCentered}>{props.user}</div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
