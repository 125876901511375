import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './admin-brands.loaders';
import { adminBrandsKeys } from './admin-brands.queries';

export const useAdminCreateBrand = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createBrand, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminBrandsKeys.all);
    },
  });
};

export const useAdminUpdateBrand = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateBrand, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminBrandsKeys.all);
    },
  });
};

export const useAdminUpdateBrandContent = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateBrandContent, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminBrandsKeys.all);
    },
  });
};

export const useAdminDeleteBrand = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteBrand, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminBrandsKeys.lists);
    },
  });
};
