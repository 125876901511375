import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './admin-orders.loaders';
import { adminOrdersKeys } from './admin-orders.queries';

export const useExportToXls = () => {
  return useMutation(loaders.exportXls, {
    onSuccess: filesConverters.export,
  });
};

export const useBulkUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkUpdate, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminOrdersKeys.lists);
    },
  });
};

export const useUpdateAdminOrder = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateAdminOrder, {
    onSuccess: (_, { orderId }) => {
      return queryClient.invalidateQueries(adminOrdersKeys.detail(orderId));
    },
  });
};

export const useDownloadBulkUpdateTemplate = () => {
  return useMutation(loaders.downloadBulkUpdateTemplate, {
    onSuccess: filesConverters.export,
  });
};

export const useImportAdminOrdersBulkUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.importBulkUpdate, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminOrdersKeys.lists);
    },
  });
};
