import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { useAdminShopItem, useUpdateShopItem } from 'store/admin-shop';
import { usePredefinedToasts } from 'utils/Toast';
import { ShopItemStatus, shopItemStatuses } from 'store/shop';
import { Currency, currencies } from 'types/general';
import { ShopItemCategory, shopItemCategories } from 'types/MarketplaceItems';
import { BenefitCategory, benefitCategories } from 'store/benefits';
import {
  AutocompleteInput,
  Button,
  DataLossPrompt,
  FileInput,
  Footer,
  ImageList,
  Loader,
  NothingFoundAlert,
  NumberInput,
  Panel,
  SelectInput,
  TextField,
} from 'melp-design/components';
import { FC, useEffect } from 'react';
import { useUploadFile } from 'store/files';
import { APP, ROUTES } from 'config';
import { formatCountry } from 'utils/format';
import { useRouteParams } from 'utils/useIdParam';

interface Values {
  name: string;
  status: ShopItemStatus;
  currency: Currency;
  vatRate: number;
  countries: string[];
  cities: string[];
  benefitCategories: BenefitCategory[];
  categories: ShopItemCategory[];
  imageId?: string;
  priority: number;
  searchTags: string;
}

const INITIAL_VALUES: Values = {
  name: '',
  status: 'inactive',
  currency: 'EUR',
  vatRate: NaN,
  countries: [],
  cities: [],
  categories: [],
  benefitCategories: [],
  imageId: '',
  priority: 0,
  searchTags: '',
};

export const ShopItemSettings: FC = () => {
  const { t, i18n } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const { id } = useRouteParams(ROUTES.admin.shopItems.details.settings);

  const { data: shopItem, isLoading } = useAdminShopItem(id);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { dirtyFields },
  } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });

  const { mutate: updateShopItem, isLoading: isUpdating } = useUpdateShopItem();
  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile();

  useEffect(() => {
    if (shopItem) {
      reset({
        name: shopItem.name,
        currency: shopItem.currency,
        status: shopItem.status,
        benefitCategories: shopItem.benefitPlanTypes,
        categories: shopItem.categories,
        countries: shopItem.countries,
        cities: shopItem.cities,
        vatRate: shopItem.vatRate,
        imageId: shopItem.image?.id,
        priority: shopItem.priority,
        searchTags: shopItem.searchTags,
      });
    }
  }, [shopItem, reset]);

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateShopItem(
      {
        shopItemId: id,
        item: {
          name: values.name,
          status: values.status,
          currency: values.currency,
          vatRate: values.vatRate || 0,
          countries: values.countries,
          cities: values.cities,
          categories: values.categories,
          benefitCategories: values.benefitCategories,
          imageId: values.imageId,
          priority: values.priority,
          searchTags: values.searchTags,
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  const [imageId] = watch(['imageId']);
  const isDirty = !!Object.keys(dirtyFields).length;

  if (isLoading) return <Loader />;
  if (!shopItem) return <NothingFoundAlert />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Panel title={t('marketplaceItems.marketplaceItemSettings')}>
            <Stack spacing={2}>
              <Controller
                name="status"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <SelectInput
                    label={t('common.status')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    options={shopItemStatuses.map((status) => ({
                      value: status,
                      label: t(`status.${status}`),
                    }))}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="name"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('common.systemName')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="currency"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SelectInput
                      label={t('marketplaceItems.currency')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      options={currencies.map((currency) => ({
                        label: currency,
                        value: currency,
                      }))}
                      required
                      ref={ref}
                    />
                  );
                }}
              />

              <Controller
                name="vatRate"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <NumberInput
                    label={t('marketplaceItems.vatRate')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    key={value}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="countries"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <AutocompleteInput
                    label={t('marketplaceItems.countries')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    options={APP.countries.alpha3.map((alpha3) => ({
                      value: alpha3,
                      label: formatCountry(i18n.language, alpha3),
                    }))}
                    required
                    multiple
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="cities"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <AutocompleteInput
                    label={t('common.city.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    options={APP.cities.map((city) => ({
                      value: city,
                      label: city,
                    }))}
                    multiple
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="benefitCategories"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <AutocompleteInput
                    label={t('marketplaceItems.benefitPlanTypes')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    options={benefitCategories.map((category) => ({
                      value: category,
                      label: t(`menu.${category}`),
                    }))}
                    multiple
                    required
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="categories"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <AutocompleteInput
                    label={t('marketplaceItems.categories')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    options={shopItemCategories.map((category) => ({
                      label: t(`marketplaceItems.itemCategory-${category}`),
                      value: category,
                    }))}
                    multiple
                    required
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="priority"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <NumberInput
                    label={t('common.priority.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    type="integer"
                    key={value}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="searchTags"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('shop.search_tags.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    rows={5}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="imageId"
                control={control}
                render={({ field: { name, onChange, ref } }) => {
                  return imageId ? (
                    <ImageList
                      items={[
                        {
                          imageId,
                          actions: [
                            {
                              icon: Delete,
                              variant: 'neutral-outline',
                              onClick: () => onChange(''),
                            },
                          ],
                        },
                      ]}
                    />
                  ) : (
                    <FileInput
                      name={name}
                      onChange={([file]) => {
                        if (file) {
                          uploadFile(
                            { file },
                            {
                              onSuccess: ({ data }) => {
                                onChange(data.id);
                              },
                            },
                          );
                        } else {
                          onChange('');
                        }
                      }}
                      disabled={isUploading}
                      maxFiles={1}
                      previewSelectedFiles={false}
                      accept={['jpg', 'jpeg', 'png', 'gif', 'webp']}
                      ref={ref}
                    />
                  );
                }}
              />
            </Stack>
          </Panel>
        </Stack>

        <Footer visible={isDirty}>
          <Stack direction="row" justifyContent="end" gap="10px">
            <Button
              label={t('common.cancel')}
              variant="neutral-outline"
              onClick={() => {
                reset(undefined, { keepDirty: false });
              }}
            />
            <Button
              label={t('common.confirm_changes')}
              variant="primary"
              type="submit"
              disabled={isUpdating}
            />
          </Stack>
        </Footer>
      </form>

      <DataLossPrompt when={isDirty && !isUpdating} />
    </>
  );
};
