const DEFAULT_PREFIX = 'admin/v1';
const DEFAULT_V2_PREFIX = 'admin/v2';
const MELP_ADMIN_PREFIX = 'melp/v1';
const MELP_ADMIN_V2_PREFIX = 'melp/v2';

const PARENT_COMPANIES_PREFIX = `${DEFAULT_PREFIX}/parentCompanies`;

const PARENT_COMPANY_ENDPOINTS = {
  parentCompany: PARENT_COMPANIES_PREFIX,
  getParentCompanyHierarchy: `${PARENT_COMPANIES_PREFIX}/hierarchy`,
  getParentCompanyHierarchyById: `${PARENT_COMPANIES_PREFIX}/{id}/hierarchy`,
  getOwnCompany: `${PARENT_COMPANIES_PREFIX}/own`,
  updateDefaultLanguage: `${PARENT_COMPANIES_PREFIX}/settings`,
};

const COMPANY_GROUP_PREFIX = `${DEFAULT_PREFIX}/companyGroup`;

const COMPANY_GROUP_ENDPOINTS = {
  companyGroup: COMPANY_GROUP_PREFIX,
  createCompanyGroup: `${COMPANY_GROUP_PREFIX}/create`,
  companyGroupFilterValues: `${COMPANY_GROUP_PREFIX}/filterValues`,
};

const EMPLOYEES_PREFIX = `${DEFAULT_PREFIX}/employee`;

const EMPLOYEE_ENDPOINTS = {
  employee: EMPLOYEES_PREFIX,
  createEmployee: `${EMPLOYEES_PREFIX}/create`,
};

const START_GUIDE_PREFIX = `${DEFAULT_PREFIX}/startGuide`;

const START_GUIDE_ENDPOINTS = {
  actionNeeded: `${START_GUIDE_PREFIX}/actionNeeded`,
  sixSteps: `${START_GUIDE_PREFIX}/sixSteps`,
};

const COMPANY_PREFIX = `${DEFAULT_PREFIX}/companies`;

const COMPANY_ENDPOINTS = {
  company: COMPANY_PREFIX,
  createCompany: `${COMPANY_PREFIX}/create`,
  companyFilterValues: `${COMPANY_PREFIX}/filterValues`,
};

const ADMIN_PREFIX = `${DEFAULT_PREFIX}/admins`;

const ADMIN_ENDPOINTS = {
  admin: ADMIN_PREFIX,
  me: `${ADMIN_PREFIX}/me`,
  createAdmin: `${ADMIN_PREFIX}/create`,
  adminFilterValues: `${ADMIN_PREFIX}/filterValues`,
  createProgrammatic: `${ADMIN_PREFIX}/createProgrammatic`,
  deleteHrAdmin: `${ADMIN_PREFIX}/hr`,
  deleteMelpAdmin: `${ADMIN_PREFIX}/melp`,
};

const PERMISSION_PREFIX = `${DEFAULT_PREFIX}/permissions`;

const PERMISSION_ENDPOINTS = {
  localPermissions: `${PERMISSION_PREFIX}/local`,
  globalPermissions: `${PERMISSION_PREFIX}/global`,
  companyGroupPermissions: `${PERMISSION_PREFIX}/companyGroup`,
  companyPermissions: `${PERMISSION_PREFIX}/company`,
};

const EMPLOYEE_CATEGORIES_PREFIX = `${DEFAULT_PREFIX}/employeeCategories`;

const EMPLOYEE_CATEGORIES_ENDPOINTS = {
  employeeCategories: EMPLOYEE_CATEGORIES_PREFIX,
  createEmployeeCategories: `${EMPLOYEE_CATEGORIES_PREFIX}/create`,
};

const NEWS_PREFIX = `${DEFAULT_PREFIX}/news`;

const NEWS_ENDPOINTS = {
  recipients: `${NEWS_PREFIX}`,
  assignRecipients: `${NEWS_PREFIX}/{id}/assign/recipients`,
};

const GLOBAL_SEARCH_ENDPOINTS = {
  globalSearch: `${DEFAULT_PREFIX}/search`,
};

const ANALYTICS_INVESTMENT_PREFIX = `${DEFAULT_PREFIX}/analytics/investment`;

const ANALYTICS_ENDPOINTS = {
  monthlyInvestments: `${ANALYTICS_INVESTMENT_PREFIX}/monthlyInvestments`,
  benefitTypesInvestments: `${ANALYTICS_INVESTMENT_PREFIX}/benefitTypes`,
  aggregatedInvestments: `${ANALYTICS_INVESTMENT_PREFIX}/aggregated`,
};

const MARKETPLACE_SUPPLIERS = {
  marketplaceSuppliers: `${MELP_ADMIN_PREFIX}/marketplaceSupplier`,
  marketplaceSupplier: (id: string) =>
    `${MELP_ADMIN_PREFIX}/marketplaceSupplier/${id}`,
};

export const Endpoints = {
  ...PARENT_COMPANY_ENDPOINTS,
  ...COMPANY_GROUP_ENDPOINTS,
  ...EMPLOYEE_ENDPOINTS,
  ...START_GUIDE_ENDPOINTS,
  ...COMPANY_ENDPOINTS,
  ...ADMIN_ENDPOINTS,
  ...PERMISSION_ENDPOINTS,
  ...EMPLOYEE_CATEGORIES_ENDPOINTS,
  ...NEWS_ENDPOINTS,
  ...GLOBAL_SEARCH_ENDPOINTS,
  ...ANALYTICS_ENDPOINTS,
  ...MARKETPLACE_SUPPLIERS,
  clientAdmin: {
    companies: {
      root: `${DEFAULT_PREFIX}/companies`,
      departments: `${DEFAULT_PREFIX}/parentCompanies/hierarchy/fullHierarchy`,
      byId: (companyId: string) => ({
        contacts: {
          root: `${DEFAULT_PREFIX}/companies/${companyId}/contacts`,
        },
      }),
      contacts: {
        byId: (contactId: string) => ({
          root: `${DEFAULT_PREFIX}/companies/contacts/${contactId}`,
        }),
      },
    },
    employee: {
      birthdays: `${DEFAULT_PREFIX}/employee/birthdays`,
      workAnniversaries: `${DEFAULT_PREFIX}/employee/workAnniversaries`,
      exportToExcel: `${DEFAULT_PREFIX}/employee/exportToExcel/new`,
      byId: (employeeId: string) => ({
        root: `${DEFAULT_PREFIX}/employee/${employeeId}`,
      }),
    },
    marketplaceItems: {
      selection: {
        marketplaceItem: (marketplaceItemId: string) =>
          `${DEFAULT_PREFIX}/marketplaceItems/selection/${marketplaceItemId}`,
        bulk: `${DEFAULT_PREFIX}/marketplaceItems/selection/bulk`,
      },
    },
    benefitPlans: {
      root: `${DEFAULT_PREFIX}/benefitPlans`,
      byId: (benefitPlanId: string) => ({
        root: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}`,
        content: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/content`,
        copy: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/copy`,
        employeeAssignments: {
          filterValues: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/employeeAssignments/filterValues`,
        },
        employeeGroupAssignments: {
          filterValues: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/employeeGroupAssignments/filterValues`,
        },
        marketplaceItems: {
          root: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems`,
          byId: (marketplaceItemId: string) => ({
            root: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems/${marketplaceItemId}`,
          }),
          bulkLink: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems/link/bulk`,
          bulkUnlink: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems/unlink/bulk`,
          bulkLinkSelection: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems/link/selection`,
          assignmentFilter: {
            root: `${DEFAULT_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems/assignmentFilter`,
          },
        },
      }),
    },
    clientIntegrationLogs: {
      root: (clientIntegrationTypeId: string) =>
        `${DEFAULT_PREFIX}/client-integration-logs/${clientIntegrationTypeId}`,
    },
    clientIntegrationLogItems: {
      root: (processId: string) =>
        `${DEFAULT_PREFIX}/client-integration-log-items/${processId}`,
    },
    benefitPlanTemplates: {
      root: `${DEFAULT_PREFIX}/benefitPlanTemplate`,
    },
    alternatives: {
      root: `${DEFAULT_V2_PREFIX}/benefitAlternatives`,
      root_v1: `${DEFAULT_PREFIX}/benefitAlternatives`,
      export: {
        xls: `${DEFAULT_PREFIX}/benefitAlternatives/export/excel`,
      },
      byId: {
        root: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId`,
        employeeGroups: {
          root: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/employeeGroups`,
          assignments: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/employeeGroups/assignments`,
        },
        employees: {
          root: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/employees`,
          assignments: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/employees/assignments`,
          byId: {
            lockSelection: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/employees/:employeeId/expire`,
            selectBenefits: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/employees/:employeeId/benefits`,
          },
        },
        translations: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/translations`,
        settings: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/settings`,
        benefits: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/benefits`,
        status: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/status`,
        deadline: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/bulk/change-deadline`,
        lockSelection: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/bulk/lock-selection`,
        selectBenefits: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/bulk/select-benefits`,
      },
      reports: {
        byBenefits: {
          root: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/report/benefit`,
          export: {
            xls: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/report/benefit/export/excel`,
          },
        },
        byBenefitCategory: {
          root: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/report/benefit-category`,
          export: {
            xls: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/report/benefit-category/export/excel`,
          },
        },
        byCompany: {
          root: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/report/company`,
          export: {
            xls: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/report/company/export/excel`,
          },
        },
        byCustomCategory: {
          root: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/report/employee-category/:customCategoryId`,
          export: {
            xls: `${DEFAULT_PREFIX}/benefitAlternatives/:alternativeId/report/employee-category/:customCategoryId/export/excel`,
          },
        },
      },
    },
    administrators: {
      root: `${DEFAULT_PREFIX}/admins`,
    },
    shop: {
      root: `${DEFAULT_V2_PREFIX}/marketplaceItems`,
      internal: {
        root: `${DEFAULT_PREFIX}/marketplace-company-items`,
        byId: `${DEFAULT_PREFIX}/marketplace-company-items/:itemId`,
        inventory: {
          root: `${DEFAULT_PREFIX}/marketplace-company-items/variation/inventory`,
          byId: `${DEFAULT_PREFIX}/marketplace-company-items/variation/:variantId/inventory`,
          export: {
            xls: `${DEFAULT_PREFIX}/marketplace-company-items/variation/:variantId/inventory/export/excel`,
          },
          updateLowQuantity: `${DEFAULT_PREFIX}/marketplace-company-items/variation/:variantId/low-inventory-alert`,
        },
      },
    },
    suppliers: {
      root: `${DEFAULT_PREFIX}/marketplaceSupplier`,
    },
    wallets: {
      root: `${DEFAULT_PREFIX}/employee/wallet`,
      totals: `${DEFAULT_PREFIX}/employee/wallet/total`,
      export: {
        xls: `${DEFAULT_PREFIX}/employee/wallet/export/excel`,
      },
    },
    expenses: {
      root: `${DEFAULT_PREFIX}/expenses`,
      byId: `${DEFAULT_PREFIX}/expenses/:expenseId`,
      export: {
        xls: `${DEFAULT_PREFIX}/expenses/export/excel`,
      },
      update: {
        status: {
          bulk: `${DEFAULT_PREFIX}/expenses/status/bulk`,
        },
      },
    },
    logs: {
      root: `${DEFAULT_V2_PREFIX}/actionLog`,
    },
    claims: {
      root: `${DEFAULT_PREFIX}/claim-reimbursement`,
      byId: `${DEFAULT_PREFIX}/claim-reimbursement/:claimId`,
      bulk: `${DEFAULT_PREFIX}/claim-reimbursement/bulk`,
      export: {
        xls: `${DEFAULT_PREFIX}/claim-reimbursement/export/excel`,
      },
    },
    recognitions: {
      root: `${DEFAULT_PREFIX}/recognition/feed`,
      byId: `${DEFAULT_PREFIX}/recognition/:recognitionId`,
      settings: `${DEFAULT_PREFIX}/recognition/settings`,
      expiration: `${DEFAULT_PREFIX}/recognition/manual-expiration`,
      export: {
        xls: `${DEFAULT_PREFIX}/recognition/feed/exportToExcel`,
        template: `${DEFAULT_PREFIX}/recognition/import/excel`,
      },
      approve: {
        byId: `${DEFAULT_PREFIX}/recognition/approval/:recognitionId`,
      },
      import: `${DEFAULT_PREFIX}/recognition/import/excel`,
      recipients: {
        root: `${DEFAULT_PREFIX}/recognition/recipients`,
        byId: `${DEFAULT_PREFIX}/recognition/recipients/employee/:employeeId`,
        export: {
          xls: `${DEFAULT_PREFIX}/recognition/recipients/exportToExcel`,
        },
      },
      givers: {
        root: `${DEFAULT_PREFIX}/recognition/givers`,
        byId: `${DEFAULT_PREFIX}/recognition/givers/employee/:employeeId`,
        export: {
          xls: `${DEFAULT_PREFIX}/recognition/givers/exportToExcel`,
        },
      },
      rewards: {
        root: `${DEFAULT_PREFIX}/recognition/marketplaceItems`,
        byId: `${DEFAULT_PREFIX}/recognition/marketplaceItems/:marketplaceItemId`,
        config: `${DEFAULT_PREFIX}/recognition/marketplaceItems/assignmentFilter`,
        link: `${DEFAULT_PREFIX}/recognition/marketplaceItems/link`,
        unlink: `${DEFAULT_PREFIX}/recognition/marketplaceItems/unlink`,
      },
      achievements: {
        root: `${DEFAULT_PREFIX}/recognition/achievements`,
        byId: `${DEFAULT_PREFIX}/recognition/achievements/:achievementId`,
        priorities: `${DEFAULT_PREFIX}/recognition/achievements/priority`,
      },
      leaderboard: {
        root: `${DEFAULT_PREFIX}/recognition/leaderboard`,
        export: {
          xls: `${DEFAULT_PREFIX}/recognition/leaderboard/exportToExcel`,
        },
      },
    },
    employees: {
      root: `${DEFAULT_V2_PREFIX}/employee`,
      byId: {
        root: `${DEFAULT_PREFIX}/employee/:employeeId`,
        expenses: {
          root: `${DEFAULT_PREFIX}/employee/:employeeId/wallet/expenses`,
        },
        walletBenefits: {
          root: `${DEFAULT_PREFIX}/employee/:employeeId/wallet/benefits`,
        },
        walletRecognitions: {
          root: `${DEFAULT_PREFIX}/employee/:employeeId/wallet/recognition`,
        },
      },
      create: `${DEFAULT_PREFIX}/employee/create`,
      jobTitles: `${DEFAULT_PREFIX}/employee/job-titles`,
      bulk: {
        update: `${DEFAULT_PREFIX}/employee/bulkAction`,
      },
      invitations: `${DEFAULT_PREFIX}/notification/invitation`,
      export: {
        xls: `${DEFAULT_PREFIX}/employee/exportToExcel/new`,
      },
      updateAssignments: `${DEFAULT_PREFIX}/employee/:employeeId/benefits/:benefitId`,
      customCategories: {
        root: `${DEFAULT_PREFIX}/employee/custom-categories`,
        create: `${DEFAULT_PREFIX}/employeeCategories/create`,
        byId: {
          values: `${DEFAULT_PREFIX}/employeeCategories/:categoryId/values`,
        },
      },
    },
    employeeGroups: {
      root: `${DEFAULT_PREFIX}/employeeGroups`,
      rootv2: `${DEFAULT_V2_PREFIX}/employeeGroups`,
      byId: {
        root: `${DEFAULT_PREFIX}/employeeGroups/:employeeGroupId`,
        copy: `${DEFAULT_PREFIX}/employeeGroups/:employeeGroupId/copy`,
      },
      export: {
        xls: `${DEFAULT_V2_PREFIX}/employeeGroups/export/excel`,
      },
    },
    benefits: {
      root: `${DEFAULT_PREFIX}/benefitPlans`,
      lite: `${DEFAULT_PREFIX}/benefitPlans/list`,
      employee: `${DEFAULT_PREFIX}/employee/:employeeId/benefits`,
      export: {
        xls: `${DEFAULT_PREFIX}/benefitPlans/exportToExcel`,
      },
    },
    assignments: {
      employees: {
        benefits: {
          root: `${DEFAULT_PREFIX}/employees/:employeeId/assignments/benefits`,
          bulk: `${DEFAULT_PREFIX}/employees/:employeeId/assignments/benefits/bulk`,
          byAssignmentId: `${DEFAULT_PREFIX}/employees/assignments/benefits/:assignmentId`,
          preview: {
            root: `${DEFAULT_PREFIX}/employees/:employeeId/benefit-assignments`,
          },
          timeline: {
            root: `${DEFAULT_PREFIX}/employees/:employeeId/benefit-assignments/:benefitId`,
          },
        },
        employeeGroups: {
          root: `${DEFAULT_PREFIX}/employees/:employeeId/assignments/employee-groups`,
          bulk: `${DEFAULT_PREFIX}/employees/:employeeId/assignments/employee-groups/bulk`,
          byAssignmentId: `${DEFAULT_PREFIX}/employees/assignments/employee-groups/:assignmentId`,
        },
      },
      employeeGroups: {
        benefits: {
          root: `${DEFAULT_PREFIX}/employee-groups/:employeeGroupId/assignments/benefits`,
          bulk: `${DEFAULT_PREFIX}/employee-groups/:employeeGroupId/assignments/benefits/bulk`,
        },
        employees: {
          root: `${DEFAULT_PREFIX}/employee-groups/:employeeGroupId/assignments/employees`,
          bulk: `${DEFAULT_PREFIX}/employee-groups/:employeeGroupId/assignments/employees/bulk`,
        },
      },
      benefits: {
        employees: {
          root: `${DEFAULT_PREFIX}/benefits/:benefitId/assignments/employees`,
          bulk: `${DEFAULT_PREFIX}/benefits/:benefitId/assignments/employees/bulk`,
        },
        employeeGroups: {
          root: `${DEFAULT_PREFIX}/benefits/:benefitId/assignments/employee-groups`,
          bulk: `${DEFAULT_PREFIX}/benefits/:benefitId/assignments/employee-groups/bulk`,
          byAssignmentId: `${DEFAULT_PREFIX}/benefits/assignments/employee-groups/:assignmentId`,
        },
      },
      history: {
        employee_benefit: `${DEFAULT_PREFIX}/employees/:employeeId/assignments/benefits/:benefitId/history`,
        benefit_employeeGroup: `${DEFAULT_PREFIX}/benefits/:benefitId/assignments/employee-groups/:employeeGroupId/history`,
        employee_employeeGroup: `${DEFAULT_PREFIX}/employees/:employeeId/assignments/employee-groups/:employeeGroupId/history`,
      },
    },
    orders: {
      root: `${DEFAULT_PREFIX}/marketplaceOrders`,
      byId: `${DEFAULT_PREFIX}/marketplaceOrders/:orderId`,
      export: {
        xls: `${DEFAULT_PREFIX}/marketplaceOrders/export/excel`,
      },
    },
    automations: {
      root: `${DEFAULT_PREFIX}/automations`,
      byId: {
        root: `${DEFAULT_PREFIX}/automations/:automationId`,
        execute: `${DEFAULT_PREFIX}/automations/:automationId/execute`,
      },
    },
    integrations: {
      root: `${DEFAULT_PREFIX}/integrations`,
      byId: `${DEFAULT_PREFIX}/integrations/:integrationId`,
      models: {
        root: `${DEFAULT_PREFIX}/integrationTypes`,
        byId: `${DEFAULT_PREFIX}/integrationTypes/:integrationModelId`,
      },
    },
    surveys: {
      root: `${DEFAULT_PREFIX}/surveys`,
      byId: (surveyId: string) => ({
        root: `${DEFAULT_PREFIX}/surveys/${surveyId}`,
        questions: {
          root: `${DEFAULT_PREFIX}/surveys/${surveyId}/questions`,
        },
        groupAssignments: {
          root: `${DEFAULT_PREFIX}/surveys/${surveyId}/group-assignments`,
        },
        activate: {
          root: `${DEFAULT_PREFIX}/surveys/${surveyId}/activate`,
        },
        deactivate: {
          root: `${DEFAULT_PREFIX}/surveys/${surveyId}/deactivate`,
        },
        results: {
          root: `${DEFAULT_PREFIX}/surveys/${surveyId}/results`,
        },
        exportToExcel: {
          root: `${DEFAULT_PREFIX}/surveys/${surveyId}/exportToExcel`,
        },
        sendNotifications: {
          root: `${DEFAULT_PREFIX}/surveys/${surveyId}/send-notifications`,
        },
      }),
    },
    recognition: {
      achievements: {
        root: `${DEFAULT_PREFIX}/recognition/achievements`,
        byId: (achievementId: string) => ({
          root: `${DEFAULT_PREFIX}/recognition/achievements/${achievementId}`,
        }),
      },
      allowanceRules: {
        root: `${DEFAULT_PREFIX}/recognition/allowance-rules`,
        byId: (allowanceRuleId: string) => ({
          root: `${DEFAULT_PREFIX}/recognition/allowance-rules/${allowanceRuleId}`,
        }),
      },
      allowance: {
        overview: {
          root: `${DEFAULT_PREFIX}/recognition/allowance/overview`,
          exportToExcel: `${DEFAULT_PREFIX}/recognition/allowance/overview/exportToExcel`,
          employee: {
            byId: (employeeId: string) => ({
              root: `${DEFAULT_PREFIX}/recognition/allowance/overview/employee/${employeeId}`,
            }),
          },
        },
      },
    },
    news: {
      root: `${DEFAULT_V2_PREFIX}/news`,
      create: `${DEFAULT_PREFIX}/news/create`,
      exportToExcel: `${DEFAULT_PREFIX}/news/exportToExcel`,
      byId: {
        root: `${DEFAULT_PREFIX}/news/:newId`,
        copy: `${DEFAULT_PREFIX}/news/:newId/copy`,
        recipients: `${DEFAULT_PREFIX}/news/:newId/recipients`,
        assignRecipients: `${DEFAULT_PREFIX}/news/:newId/assign/recipients`,
      },
      categories: {
        root: `${DEFAULT_PREFIX}/news-categories`,
        byId: `${DEFAULT_PREFIX}/news-categories/:categoryId`,
      },
    },
    discounts: {
      root: `${DEFAULT_PREFIX}/discount`,
      bulkUpdate: `${DEFAULT_PREFIX}/discount/bulkAction`,
    },
    trustBox: {
      root: `${DEFAULT_PREFIX}/trustbox`,
    },
    automationRules: {
      root: `${DEFAULT_PREFIX}/automation-rules`,
      byId: (id: string) => ({
        root: `${DEFAULT_PREFIX}/automation-rules/${id}`,
      }),
    },
    v2: {
      marketplaceItems: {
        root: `${DEFAULT_V2_PREFIX}/marketplaceItems`,
        benefitItems: (benefitId: string) =>
          `${DEFAULT_V2_PREFIX}/marketplaceItems/benefitPlan/${benefitId}`,
        selection: {
          root: `${DEFAULT_V2_PREFIX}/marketplaceItems/selection`,
        },
      },
      benefitPlans: {
        byId: (benefitPlanId: string) => ({
          marketplaceItems: {
            root: `${DEFAULT_V2_PREFIX}/benefitPlans/${benefitPlanId}/marketplaceItems`,
          },
        }),
      },
    },
  },
  melpAdmin: {
    admins: {
      root: `${MELP_ADMIN_PREFIX}/admins`,
      byId: `${MELP_ADMIN_PREFIX}/admins/:adminId`,
      create: `${MELP_ADMIN_PREFIX}/admins/create`,
    },
    companies: {
      info: `${MELP_ADMIN_PREFIX}/company/info`,
    },
    clients: {
      root: `${DEFAULT_PREFIX}/parentCompanies`,
      byId: `${DEFAULT_PREFIX}/parentCompanies/:clientId`,
      create: `${DEFAULT_PREFIX}/parentCompanies/create`,
      deposits: {
        byId: `${MELP_ADMIN_PREFIX}/deposits/:depositId`,
        clients: {
          root: `${MELP_ADMIN_PREFIX}/deposits/parent-companies/:clientId`,
          history: `${MELP_ADMIN_PREFIX}/deposits/parent-companies/:clientId/deposits`,
          balances: `${MELP_ADMIN_PREFIX}/deposits/parent-companies/deposits`,
          enableDeposits: `${MELP_ADMIN_PREFIX}/deposits/parent-companies/:clientId/tracking`,
        },
        companies: {
          root: `${MELP_ADMIN_PREFIX}/deposits/companies/:companyId`,
          settings: `${MELP_ADMIN_PREFIX}/deposits/companies/:companyId/settings`,
          expenses: `${MELP_ADMIN_PREFIX}/deposits/companies/:companyId/expenses`,
          balances: `${MELP_ADMIN_PREFIX}/deposits/companies/deposits`,
          export: {
            xls: `${MELP_ADMIN_PREFIX}/deposits/companies/:companyId/export/excel`,
          },
        },
        events: {
          root: `${MELP_ADMIN_PREFIX}/deposits/tracking`,
          byId: `${MELP_ADMIN_PREFIX}/deposits/tracking/:eventId`,
        },
        pendingActions: `${MELP_ADMIN_PREFIX}/deposits/balances/requiresAction`,
      },
      balance: {
        export: {
          xls: `${MELP_ADMIN_PREFIX}/parentCompanies/:clientId/balance/:companyId/exportToExcel`,
        },
      },
    },
    brands: {
      root: `${MELP_ADMIN_PREFIX}/brands`,
      byId: {
        root: `${MELP_ADMIN_PREFIX}/brands/:brandId`,
        content: `${MELP_ADMIN_PREFIX}/brands/:brandId/content`,
        cards: `${MELP_ADMIN_PREFIX}/brands/:brandId/cards`,
      },
    },
    cards: {
      root: `${MELP_ADMIN_PREFIX}/cards`,
      byId: {
        root: `${MELP_ADMIN_PREFIX}/cards/:cardId`,
        update: `${MELP_ADMIN_PREFIX}/brands/cards/:cardId`,
        content: `${MELP_ADMIN_PREFIX}/cards/:cardId/content`,
        prices: `${MELP_ADMIN_PREFIX}/cards/:cardId/prices`,
      },
      bulk: {
        xls: `${MELP_ADMIN_PREFIX}/cards/bulk-update/excel`,
      },
    },
    orders: {
      root: `${MELP_ADMIN_PREFIX}/marketplaceOrders`,
      byId: `${MELP_ADMIN_PREFIX}/marketplaceOrders/:orderId`,
      filterValues: `${MELP_ADMIN_PREFIX}/marketplaceOrders/filterValues`,
      bulk: {
        update: `${MELP_ADMIN_PREFIX}/marketplaceOrders/bulkAction`,
        xls: `${MELP_ADMIN_PREFIX}/marketplaceOrders/bulk-update/excel`,
      },
      export: {
        xls: `${MELP_ADMIN_PREFIX}/marketplaceOrders/exportToExcel`,
      },
    },
    discounts: {
      root: `${MELP_ADMIN_PREFIX}/discount`,
      create: `${MELP_ADMIN_PREFIX}/discount/create`,
      byId: `${MELP_ADMIN_PREFIX}/discount/:discountId`,
      export: {
        xls: `${MELP_ADMIN_PREFIX}/discount/exportToExcel`,
      },
      codes: {
        root: `${MELP_ADMIN_PREFIX}/discount/:discountId/uniqueCodes`,
        status: `${MELP_ADMIN_PREFIX}/discount/uniqueCode/status`,
        import: {
          xls: `${MELP_ADMIN_PREFIX}/discount/uniqueCode/import/excel`,
        },
      },
    },
    shopItems: {
      root: `${MELP_ADMIN_PREFIX}/marketplaceItems`,
      create: `${MELP_ADMIN_PREFIX}/marketplaceSupplier/:supplierId/items`,
      byId: `${MELP_ADMIN_V2_PREFIX}/marketplaceItems/:shopItemId`,
      export: {
        xls: `${MELP_ADMIN_PREFIX}/marketplaceItems/exportToExcel`,
      },
      import: {
        updatesFile: `${MELP_ADMIN_PREFIX}/marketplaceItems/import/update/excel`,
      },
      info: `${MELP_ADMIN_PREFIX}/marketplaceItems/info`,
    },
    shopVouchers: {
      root: `${MELP_ADMIN_PREFIX}/vouchers/marketplaceItem/:shopItemId`,
      byId: `${MELP_ADMIN_PREFIX}/vouchers/:voucherId`,
      status: `${MELP_ADMIN_PREFIX}/vouchers/status`,
      import: {
        xls: `${MELP_ADMIN_PREFIX}/vouchers/import/excel`,
        files: `${MELP_ADMIN_PREFIX}/vouchers/import/files`,
      },
      export: {
        xls: `${MELP_ADMIN_PREFIX}/vouchers/export/excel`,
      },
    },
    suppliers: {
      root: `${MELP_ADMIN_PREFIX}/marketplaceSupplier`,
      statistics: `${MELP_ADMIN_PREFIX}/marketplaceSupplier/statistics`,
    },
    benefitPlanTemplates: {
      root: `${MELP_ADMIN_PREFIX}/benefitPlanTemplate`,
      create: `${MELP_ADMIN_PREFIX}/benefitPlanTemplate/create`,
      filterValues: `${MELP_ADMIN_PREFIX}/benefitPlanTemplate/filterValues`,
      byId: (templateId: string) => ({
        root: `${MELP_ADMIN_PREFIX}/benefitPlanTemplate/${templateId}`,
        copy: `${MELP_ADMIN_PREFIX}/benefitPlanTemplate/${templateId}/copy`,
      }),
    },
    reports: {
      export: {
        expiredBenefits: `${MELP_ADMIN_PREFIX}/employee/wallet/expiredBenefits/exportToExcel`,
        searchAttempts: `${MELP_ADMIN_PREFIX}/requestLogs/exportToExcel`,
        shopItemsContent: `${MELP_ADMIN_PREFIX}/marketplaceItems/reports/marketplaceItemsContent/exportToExcel`,
        shopItemsPriceSettings: `${MELP_ADMIN_PREFIX}/marketplaceItems/reports/marketplaceItemsPrices/exportToExcel`,
        discountsUsage: `${MELP_ADMIN_PREFIX}/discount/delta/exportToExcel`,
        vouchersStock: `${MELP_ADMIN_PREFIX}/vouchers/report/stock/excel`,
      },
    },
    marketplaceItems: {
      byId: (itemId: string) => ({
        root: `${MELP_ADMIN_PREFIX}/marketplaceItems/${itemId}`,
        translations: `${MELP_ADMIN_PREFIX}/marketplaceItems/${itemId}/translations`,
        prices: {
          root: `${MELP_ADMIN_PREFIX}/marketplaceItems/${itemId}/prices`,
          byId: (priceId: string) => ({
            root: `${MELP_ADMIN_PREFIX}/marketplaceItems/${itemId}/prices/${priceId}`,
            translations: {
              root: `${MELP_ADMIN_PREFIX}/marketplaceItems/${itemId}/prices/${priceId}/translations`,
              byId: (translationId: string) => ({
                root: `${MELP_ADMIN_PREFIX}/marketplaceItems/${itemId}/prices/${priceId}/translations/${translationId}`,
              }),
            },
          }),
        },
      }),
      translations: {
        byId: (translationId: string) => ({
          root: `${MELP_ADMIN_PREFIX}/marketplaceItems/translations/${translationId}`,
        }),
      },
    },
    logs: {
      root: `${MELP_ADMIN_PREFIX}/actionLog`,
    },
    parentCompanies: {
      byId: (parentCompanyId: string) => ({
        root: `${MELP_ADMIN_PREFIX}/parentCompanies/${parentCompanyId}`,
      }),
    },
    v2: {
      marketplaceItems: {
        byId: (itemId: string) => ({
          root: `${MELP_ADMIN_V2_PREFIX}/marketplaceItems/${itemId}`,
        }),
      },
    },
  },
  files: {
    byId: 'v1/files/:fileId',
    upload: 'v1/files/upload',
  },
};
