import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { IAdminDiscountCode, IFileImport } from 'types/income';
import { TableDataResponse } from 'types/Table';
import { exportXlsxConfig, getFormData } from 'utils/general';
import {
  AdminDiscountCodesFilters,
  DiscountCodeStatus,
} from './admin-discount-codes.types';

interface GetDiscountCodes {
  discountId: string;
  filters: Partial<AdminDiscountCodesFilters>;
}

interface ImportCodesXls {
  discountId: string;
  file: File;
}

interface UpdateStatus {
  codes: string[];
  status: DiscountCodeStatus;
}

export const loaders = {
  getDiscountCodes: ({ discountId, filters }: GetDiscountCodes) => {
    return api<TableDataResponse<IAdminDiscountCode>>({
      url: Endpoints.melpAdmin.discounts.codes.root.replace(
        ':discountId',
        discountId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
      },
    });
  },
  updateStatus: ({ codes, status }: UpdateStatus) => {
    return api({
      url: Endpoints.melpAdmin.discounts.codes.status,
      method: 'patch',
      data: {
        uniqueCodeId: codes,
        status,
      },
    });
  },
  exportXlsTemplate: () => {
    return api({
      url: Endpoints.melpAdmin.discounts.codes.import.xls,
      ...exportXlsxConfig,
    });
  },
  importCodesXls: ({ discountId, file }: ImportCodesXls) => {
    return api<IFileImport>({
      url: Endpoints.melpAdmin.discounts.codes.import.xls,
      method: 'post',
      data: getFormData([
        { field: 'file', value: file },
        { field: 'discountId', value: discountId },
      ]),
    });
  },
};
