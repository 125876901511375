import {
  Button,
  Link,
  ListPageHeader,
  Table,
  Typography,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  DateRangeFilter,
  FilterButton,
  Filters,
  ParentCompaniesFilter,
  SelectFilter,
} from 'components/filters';
import { APP, ROUTES } from 'config';
import FormDialog from 'components/dialogs/FormDialog/FormDialog';
import { useCreateClientForm } from 'configs/Forms/CreateClientForm';
import { CreateClientRequest } from 'types/Clients';
import { useDialog } from 'utils/Dialog';
import {
  adminClientsFiltersSchema,
  useAdminClients,
  useAdminCreateClient,
} from 'store/admin-clients';
import { useSearchParams } from 'utils/navigation';
import { COLOR_BY_STATUS, companyStatuses } from 'store/companies';
import { formatCountry } from 'utils/format';
import { usePredefinedToasts } from 'utils/Toast';
import { FC } from 'react';

const defaultFormValues = {
  companyCountry: APP.countries.default,
};

export const AdminClientsList: FC = () => {
  const { t, i18n } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const { searchParams: filters, navigate } = useSearchParams(
    adminClientsFiltersSchema,
  );

  const { data: clients, isLoading } = useAdminClients(filters);

  const { mutate: createClient, isLoading: isCreating } =
    useAdminCreateClient();

  const creationDialog = useDialog();
  const clientForm = useCreateClientForm();

  const handleSubmit = (data: CreateClientRequest) => {
    createClient(
      { ...data, adminPhone: `+${data.adminPhone}` },
      {
        onSuccess: () => {
          creationDialog.closeDialog();
          predefinedToasts.success.added();
        },
      },
    );
  };

  return (
    <>
      <ListPageHeader
        title={t('menu.clients')}
        sideContent={
          <>
            <Button
              label={t('client.new_client')}
              onClick={creationDialog.openDialog}
            />

            <FormDialog
              actionLabel={t('common.confirm')}
              onCancelClick={creationDialog.closeDialog}
              onSubmitForm={handleSubmit}
              id="create-client-form-dialog"
              title={t('client.add_client')}
              form={clientForm}
              open={creationDialog.open}
              defaultValues={defaultFormValues}
              loading={isCreating}
            />
          </>
        }
        toolbarContent={
          <Stack direction="row" justifyContent="end">
            <Filters
              value={filters}
              initialValues={adminClientsFiltersSchema.parse({})}
              onChange={(v) => navigate({ params: v })}
            >
              {({ value, initialValues, setFilter }) => (
                <>
                  <FilterButton
                    label={t('employees.company')}
                    value={value.companies}
                    initialValue={initialValues.companies}
                    onChange={(v) => setFilter('companies', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <ParentCompaniesFilter
                        value={value}
                        onApplyFilter={applyFilter}
                        onClearFilter={clearFilter}
                        onCancel={close}
                        multiple
                      />
                    )}
                  </FilterButton>

                  <FilterButton
                    label={t('clients.reportPeriod')}
                    value={value.date}
                    initialValue={initialValues.date}
                    onChange={(v) => setFilter('date', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <DateRangeFilter
                        value={value}
                        onApply={applyFilter}
                        onClear={clearFilter}
                        onCancel={close}
                        withTimezone
                        suggestions={['week', 'month', 'year']}
                      />
                    )}
                  </FilterButton>

                  <FilterButton
                    label={t('common.status')}
                    value={value.status}
                    initialValue={initialValues.status}
                    onChange={(v) => setFilter('status', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <SelectFilter
                        name="status"
                        value={value}
                        onApplyFilter={applyFilter}
                        onClearFilter={clearFilter}
                        onCancel={close}
                        multiple={false}
                        searchable={false}
                        options={companyStatuses.map((status) => ({
                          key: status,
                          label: t(`status.${status}`),
                        }))}
                      />
                    )}
                  </FilterButton>
                </>
              )}
            </Filters>
          </Stack>
        }
      />

      <Table
        data={clients?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'country',
            header: t('benefits.country'),
            cell: ({ row: { original: client } }) =>
              formatCountry(i18n.language, client.country),
          },
          {
            id: 'name',
            header: t('companies.company_name'),
            cell: ({ row: { original: client } }) => (
              <Link
                label={client.name}
                to={ROUTES.admin.clients.details.settings.replace(
                  ':id',
                  client.id,
                )}
              />
            ),
          },
          {
            id: 'activeMobileUsers',
            header: t('clients.activeMobileUsers'),
            cell: ({ row: { original: client } }) => client.activeEmployees,
            meta: { align: 'right' },
          },
          {
            id: 'activeEmployees',
            header: t('clients.activeEmployees'),
            cell: ({ row: { original: client } }) => client.activeEmployees,
            meta: { align: 'right' },
          },
          {
            id: 'inactiveEmployees',
            header: t('clients.inactiveEmployees'),
            cell: ({ row: { original: client } }) => client.inactiveEmployees,
            meta: { align: 'right' },
          },
          {
            id: 'internalComment',
            header: t('clients.comment'),
            cell: ({ row: { original: client } }) => client.internalComment,
          },
          {
            id: 'status',
            header: t('common.current_status'),
            cell: ({ row: { original: client } }) => (
              <Typography color={COLOR_BY_STATUS[client.status]}>
                {t(`status.${client.status}`)}
              </Typography>
            ),
          },
        ]}
      />
    </>
  );
};
