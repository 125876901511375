import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './admin-brand-cards.loaders';
import { converters } from './admin-brand-cards.converters';
import { AdminBrandCardsFilters } from './admin-brand-cards.types';

export const adminBrandCardsKeys = queryKeyFactory('admin-brand-cards');

export const useAdminBrandCards = (
  filters: Partial<AdminBrandCardsFilters> = {},
) => {
  return useQuery({
    queryKey: adminBrandCardsKeys.list(filters),
    queryFn: () => loaders.getCards({ filters }),
    select: converters.getCards,
    keepPreviousData: true,
  });
};

export const useAdminBrandCard = (cardId: string) => {
  return useQuery({
    queryKey: adminBrandCardsKeys.detail(cardId),
    queryFn: () => loaders.getCard({ cardId }),
    select: converters.getCard,
    enabled: !!cardId,
  });
};
