import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './admin-brands.loaders';
import { converters } from './admin-brands.converters';
import { AdminBrandsFilters } from './admin-brands.types';

export const adminBrandsKeys = queryKeyFactory('admin-brands');

export const useAdminBrands = (filters: Partial<AdminBrandsFilters> = {}) => {
  return useQuery({
    queryKey: adminBrandsKeys.list(filters),
    queryFn: () => loaders.getBrands({ filters }),
    select: converters.getBrands,
    keepPreviousData: true,
  });
};

export const useAdminBrand = (brandId: string) => {
  return useQuery({
    queryKey: adminBrandsKeys.detail(brandId),
    queryFn: () => loaders.getBrand({ brandId }),
    select: converters.getBrand,
    enabled: !!brandId,
  });
};
