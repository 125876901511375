import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import {
  DetailsPageContent,
  DetailsPageHeader,
  SideMenu,
  Typography,
} from 'melp-design/components';
import { ROUTES } from 'config';
import { useRouteMatch } from 'react-router-dom';
import { useRouteParams } from 'utils/useIdParam';
import { useAdminBrandCard } from 'store/admin-brand-cards';

export const AdminBrandCardLayout: FC = ({ children }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { id } = useRouteParams(ROUTES.admin.cards.settings);

  const { data: card } = useAdminBrandCard(id);

  return (
    <Stack>
      <DetailsPageHeader
        title={card?.brand?.name ?? ''}
        // side={
        //   brand ? (
        //     <AdminAdminsActions id={brand.id} name={brand.name} />
        //   ) : null
        // }
        backTo={ROUTES.admin.cards.list}
        under={
          card ? (
            <Typography color="textSecondary">
              {t('marketplace.employeeID', { id: card.sequence })}
            </Typography>
          ) : null
        }
      />

      <DetailsPageContent>
        <SideMenu
          tabs={[
            {
              key: 'settings',
              label: t('common.settings'),
              url: ROUTES.admin.cards.settings.replace(':id', id),
              isActive: path === ROUTES.admin.cards.settings,
              disabled: !card,
            },
            {
              key: 'price',
              label: t('marketplaceItems.marketplaceItemPriceSettings'),
              url: ROUTES.admin.cards.price.replace(':id', id),
              isActive: path === ROUTES.admin.cards.price,
              disabled: !card,
            },
            {
              key: 'content',
              label: t('benefitTemplates.templateContent'),
              url: ROUTES.admin.cards.content.replace(':id', id),
              isActive: path === ROUTES.admin.cards.content,
              disabled: !card,
            },
            {
              key: 'fees',
              label: t('cards.fees.title'),
              url: ROUTES.admin.cards.fees.replace(':id', id),
              isActive: path === ROUTES.admin.cards.fees,
              disabled: !card,
            },
          ]}
        />
        {children}
      </DetailsPageContent>
    </Stack>
  );
};
