import { FC, useState } from 'react';
import {
  AutocompleteInput,
  Button,
  Modal,
  NumberInput,
  SelectInput,
  SortableList,
  TextField,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { Box, Stack } from '@mui/material';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form-latest';
import { usePredefinedToasts } from 'utils/Toast';
import { useAdminBrands } from 'store/admin-brands';
import { Delete, DragIndicator } from '@mui/icons-material';
import { routerHistory } from 'utils/router-history';
import { APP, ROUTES } from 'config';
import { currencies, Currency } from 'types/general';
import { useAdminCreateBrandCard } from 'store/admin-brand-cards';
import { formatCountry } from 'utils/format';
import { useDebouncedCallback } from 'use-debounce';
import { SystemColors } from 'melp-design/style';
import { toCents } from 'utils/general';

interface Values {
  brandId: string;
  prices: Array<{
    value: number;
    label: string;
  }>;
  currency: Currency;
  countries: string[];
}

const INITIAL_VALUES: Values = {
  brandId: '',
  prices: [{ label: '', value: NaN }],
  currency: 'EUR',
  countries: [],
};

interface Props extends ModalProps {}

export const CreateBrandCardModal: FC<Props> = ({ closeModal }) => {
  const { t, i18n } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const [brandSearch, setBrandSearch] = useState('');
  const handleBrandSearch = useDebouncedCallback(setBrandSearch, 300);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });
  const {
    fields: pricesFields,
    append: appendPrice,
    move: movePrices,
    remove: removePrice,
  } = useFieldArray({ name: 'prices', control });

  const { data: brands } = useAdminBrands({ search: brandSearch });

  const { mutate: createCard, isLoading: isCreating } =
    useAdminCreateBrandCard();

  const onSubmit: SubmitHandler<Values> = (values) => {
    createCard(
      {
        brandId: values.brandId,
        prices: values.prices.map((price) => ({
          label: price.label,
          value: toCents(price.value),
        })),
        currency: values.currency,
        countries: values.countries,
      },
      {
        onSuccess: ({ data }) => {
          predefinedToasts.success.added();
          closeModal({ action: 'CLOSE' });
          routerHistory.push(
            ROUTES.admin.cards.settings.replace(':id', data.cardId),
          );
        },
      },
    );
  };

  const [prices] = watch(['prices']);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('cards.actions.create')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.close'),
          },
          {
            variant: 'primary',
            label: t('common.save'),
            type: 'submit',
            disabled: !isDirty || isCreating,
          },
        ]}
      >
        <Stack gap={2}>
          <Controller
            name="brandId"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <AutocompleteInput
                  label={t('brands.title')}
                  name={name}
                  value={value}
                  multiple={false}
                  required
                  onChange={onChange}
                  options={
                    brands?.items.map((brand) => ({
                      label: brand.name,
                      value: brand.id,
                    })) ?? []
                  }
                  onInputChange={handleBrandSearch}
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="countries"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <AutocompleteInput
                label={t('marketplaceItems.countries')}
                name={name}
                value={value}
                onChange={onChange}
                options={APP.countries.alpha3.map((alpha3) => ({
                  value: alpha3,
                  label: formatCountry(i18n.language, alpha3),
                }))}
                required
                multiple
                ref={ref}
              />
            )}
          />

          <Controller
            name="currency"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <SelectInput
                  label={t('marketplaceItems.currency')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  options={currencies.map((currency) => ({
                    label: currency,
                    value: currency,
                  }))}
                  required
                  ref={ref}
                />
              );
            }}
          />

          <Stack>
            <SortableList
              key={JSON.stringify(pricesFields.map((field) => field.id))}
              onPositionChange={movePrices}
              disabled
              items={pricesFields.map((field, i) => ({
                id: field.id,
                content: (
                  <Stack
                    direction="row"
                    spacing={2}
                    py={1}
                    px={2}
                    sx={{ backgroundColor: SystemColors.white }}
                    borderRadius={1}
                    boxShadow={` 0 0 0 1px ${SystemColors.grey[90]}`}
                    key={field.id}
                  >
                    <Box flexShrink={0} height={52}>
                      <DragIndicator
                        sx={{
                          height: '100%',
                          color: SystemColors.grey[55],
                        }}
                      />
                    </Box>

                    <Box flex={0.35}>
                      <Controller
                        name={`prices.${i}.value`}
                        control={control}
                        render={({ field: { name, value, onChange, ref } }) => (
                          <NumberInput
                            label={t('marketplace.ordersTablePrice')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            required
                            key={value}
                            ref={ref}
                          />
                        )}
                      />
                    </Box>

                    <Box flex={1}>
                      <Controller
                        name={`prices.${i}.label`}
                        control={control}
                        render={({ field: { name, value, onChange, ref } }) => (
                          <TextField
                            label={t(
                              'marketplaceOrders.marketplaceItemPriceDescription',
                            )}
                            name={name}
                            value={value}
                            onChange={onChange}
                            sx={{ width: '100%' }}
                            ref={ref}
                          />
                        )}
                      />
                    </Box>

                    <Box flexShrink={0}>
                      <Button
                        variant="danger-outline"
                        size="lg"
                        icon={Delete}
                        onClick={() => removePrice(i)}
                        disabled={prices.length <= 1}
                        title={t('common.remove')}
                      />
                    </Box>
                  </Stack>
                ),
              }))}
            />

            <Box>
              <Button
                label={t('marketplaceItems.addPrice')}
                variant="tertiary"
                onClick={() => {
                  appendPrice(INITIAL_VALUES.prices[0]);
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Modal.Content>
    </form>
  );
};
