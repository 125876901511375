import { FC } from 'react';
import { AdminBrandCardPriceSettings } from 'containers/admin';
import { AdminBrandCardLayout } from 'pages/atoms';

const AdminBrandCardPriceSettingsPage: FC = () => {
  return (
    <AdminBrandCardLayout>
      <AdminBrandCardPriceSettings />
    </AdminBrandCardLayout>
  );
};

export default AdminBrandCardPriceSettingsPage;
