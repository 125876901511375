import { Divider, Stack } from '@mui/material';
import { ROUTES } from 'config';
import {
  Button,
  DataLossPrompt,
  Footer,
  Loader,
  NothingFoundAlert,
  NumberInput,
  Panel,
  SelectInput,
} from 'melp-design/components';
import { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { useTranslation } from 'react-i18next';
import { usePredefinedToasts } from 'utils/Toast';
import { useRouteParams } from 'utils/useIdParam';
import {
  useAdminBrandCard,
  useAdminUpdateBrandCard,
} from 'store/admin-brand-cards';
import { currencies, Currency } from 'types/general';
import { fromCents } from 'utils/general';

interface Values {
  currency: Currency;
  min: number;
  max: number;
}

const INITIAL_VALUES: Values = {
  currency: 'EUR',
  min: NaN,
  max: NaN,
};

export const AdminBrandCardPriceSettings: FC = () => {
  const { t } = useTranslation();
  const { id: cardId } = useRouteParams(ROUTES.admin.cards.content);
  const predefinedToasts = usePredefinedToasts();

  const { data: card, isLoading } = useAdminBrandCard(cardId);

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });

  useEffect(() => {
    if (card) {
      reset({
        currency: card.faceValueCurrency,
        min: fromCents(card.faceValueMin),
        max: fromCents(card.faceValueMax),
      });
    }
  }, [card, reset]);

  const { mutate: updateCard, isLoading: isUpdating } =
    useAdminUpdateBrandCard();

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateCard(
      {
        cardId,
        data: {
          faceValueCurrency: values.currency,
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  if (isLoading) return <Loader />;
  if (!card) return <NothingFoundAlert />;

  const isDirty = !!Object.keys(dirtyFields).length;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Panel title={t('marketplaceItems.marketplaceItemPriceSettings')}>
            <Stack spacing={2}>
              <Controller
                name="currency"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SelectInput
                      label={t('marketplaceItems.currency')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      options={currencies.map((currency) => ({
                        label: currency,
                        value: currency,
                      }))}
                      required
                      disabled={card.provider !== 'melp'}
                      ref={ref}
                    />
                  );
                }}
              />

              {card.priceType === 'range' ? (
                <Stack flexDirection="row" gap={2}>
                  <Controller
                    name="min"
                    control={control}
                    render={({ field: { name, value, onChange, ref } }) => (
                      <NumberInput
                        label={t('marketplaceItems.priceRangeFrom')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        required
                        disabled={card.provider !== 'melp'}
                        key={value}
                        sx={{ width: '100%' }}
                        ref={ref}
                      />
                    )}
                  />

                  <Divider orientation="vertical" variant="middle" flexItem />

                  <Controller
                    name="max"
                    control={control}
                    render={({ field: { name, value, onChange, ref } }) => (
                      <NumberInput
                        label={t('marketplaceItems.priceRangeTo')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        required
                        disabled={card.provider !== 'melp'}
                        key={value}
                        sx={{ width: '100%' }}
                        ref={ref}
                      />
                    )}
                  />
                </Stack>
              ) : null}
            </Stack>
          </Panel>
        </Stack>

        <Footer visible={isDirty}>
          <Stack direction="row" justifyContent="end" gap="10px">
            <Button
              label={t('common.cancel')}
              variant="neutral-outline"
              onClick={() => {
                reset(undefined, { keepDirty: false });
              }}
            />
            <Button
              label={t('common.save')}
              type="submit"
              disabled={isUpdating}
            />
          </Stack>
        </Footer>
      </form>

      <DataLossPrompt when={isDirty && !isUpdating} />
    </>
  );
};
