import { FC } from 'react';
import {
  Link,
  ListPageHeader,
  ListTotal,
  Menu,
  Table,
  Typography,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { APP, ROUTES } from 'config';
import { useSearchParams } from 'utils/navigation';
import {
  adminBrandCardPriceTypes,
  adminBrandCardsFiltersSchema,
  adminBrandCardStatuses,
  COLOR_BY_STATUS,
  getFaceValueLabel,
  useAdminBrandCards,
  useDownloadBulkUpdateTemplate,
} from 'store/admin-brand-cards';
import { formatCountry, formatList, formatPercents } from 'utils/format';
import { useModalContext } from 'store/modal';
import {
  AdminBrandsFilter,
  FilterButton,
  Filters,
  SelectFilter,
} from 'components/filters';
import moment from 'moment';
import { adminBrandProviders } from 'store/admin-brands';
import { CreateBrandCardModal } from 'containers/admin';
import { BrandCardsBulkUpdateImportModal } from './atoms';

export const AdminBrandCardsList: FC = () => {
  const { t, i18n } = useTranslation();
  const { openModal } = useModalContext();

  const { searchParams: filters, navigate } = useSearchParams(
    adminBrandCardsFiltersSchema,
  );

  const { data: cards, isLoading } = useAdminBrandCards(filters);

  const { mutate: downloadTemplate, isLoading: isDownloadingTemplate } =
    useDownloadBulkUpdateTemplate();

  return (
    <>
      <ListPageHeader
        title={t('cards.title')}
        sideContent={
          <Menu
            label={t('common.actions')}
            items={[
              {
                label: t('cards.actions.create'),
                onClick: () => {
                  openModal({
                    component: CreateBrandCardModal,
                    size: 'lg',
                  });
                },
              },
              {
                label: t('global.actions.bulk_update'),
                onClick: () => {
                  openModal({
                    component: BrandCardsBulkUpdateImportModal,
                  });
                },
              },
              {
                label: t('dashboard.download_xls'),
                onClick: () => {
                  downloadTemplate();
                },
                disabled: isDownloadingTemplate,
              },
            ]}
          />
        }
        toolbarContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={cards?.total} />

            <Stack direction="row" alignItems="center" gap="10px">
              <Filters
                value={filters}
                initialValues={adminBrandCardsFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('common.status')}
                      value={value.status}
                      initialValue={initialValues.status}
                      onChange={(v) => setFilter('status', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="status"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple={true}
                          searchable={false}
                          options={adminBrandCardStatuses.map((status) => ({
                            key: status,
                            label: t(`status.${status}`),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('brands.title')}
                      value={value.brands}
                      initialValue={initialValues.brands}
                      onChange={(v) => setFilter('brands', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <AdminBrandsFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('benefits.country')}
                      value={value.countries}
                      initialValue={initialValues.countries}
                      onChange={(v) => setFilter('countries', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="countries"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={APP.countries.alpha3.map((alpha3) => ({
                            key: alpha3,
                            label: formatCountry(i18n.language, alpha3),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('providers.title')}
                      value={value.providers}
                      initialValue={initialValues.providers}
                      onChange={(v) => setFilter('providers', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="providers"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple={true}
                          searchable={false}
                          options={adminBrandProviders.map((provider) => ({
                            key: provider,
                            label: provider,
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('marketplaceItems.priceType')}
                      value={value.priceType}
                      initialValue={initialValues.priceType}
                      onChange={(v) => setFilter('priceType', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="priceType"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple={true}
                          searchable={false}
                          options={adminBrandCardPriceTypes.map((type) => ({
                            key: type,
                            label: t(`brands.priceType.${type}`),
                          }))}
                        />
                      )}
                    </FilterButton>
                  </>
                )}
              </Filters>
            </Stack>
          </Stack>
        }
      />

      <Table
        data={cards?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'sequence',
            header: t('integrations.hiBobServiceUserId'),
            cell: ({ row: { original: card } }) => (
              <Link
                label={card.sequence}
                to={ROUTES.admin.cards.settings.replace(':id', card.id)}
              />
            ),
            meta: { sort: true },
          },
          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: card } }) => (
              <Typography color={COLOR_BY_STATUS[card.status]}>
                {t(`status.${card.status}`)}
              </Typography>
            ),
            meta: { sort: true },
          },
          {
            id: 'brand_name',
            header: t('brands.title'),
            cell: ({ row: { original: card } }) => card.brand?.name ?? '-',
            meta: { sort: true },
          },
          {
            id: 'countries',
            header: t('marketplaceItems.countries'),
            cell: ({ row: { original: card } }) =>
              formatList(
                i18n.language,
                card.countries.map((country) =>
                  formatCountry(i18n.language, country),
                ),
              ),
          },
          {
            id: 'faceValue',
            header: t('marketplace.ordersTablePrice'),
            cell: ({ row: { original: card } }) =>
              getFaceValueLabel({ ...card, language: i18n.language }),
            meta: { align: 'right' },
          },
          {
            id: 'faceValueCurrency',
            header: t('marketplaceItems.currency'),
            cell: ({ row: { original: card } }) => card.faceValueCurrency,
          },
          {
            id: 'provider_id',
            header: t('providers.title'),
            cell: ({ row: { original: card } }) => card.provider,
            meta: { sort: true },
          },
          {
            id: 'purchasePriceDiscountPercentage',
            header: t('marketplaceItems.discountPercentage'),
            cell: ({ row: { original: card } }) =>
              formatPercents(
                i18n.language,
                card.purchasePriceDiscountPercentage,
              ),
            meta: { align: 'right' },
          },
          {
            id: 'ordersCount',
            header: t('menu.orders'),
            cell: ({ row: { original: card } }) => card.ordersCount,
            meta: { align: 'right' },
          },
          {
            id: 'creation',
            header: t('marketplaceOrders.orderDate'),
            cell: ({ row: { original: code } }) =>
              moment(code.createdAt).format('l'),
            meta: { sort: true },
          },
        ]}
        sort={{
          value: filters.sort,
          onChange: (v) => navigate({ params: { sort: v } }),
        }}
        pagination={
          cards && {
            count: cards.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </>
  );
};
