import { Stack } from '@mui/material';
import { APP, ROUTES } from 'config';
import {
  Button,
  DataLossPrompt,
  Footer,
  Loader,
  MultiLanguageInputContainer,
  NothingFoundAlert,
  Panel,
  SelectInput,
  TextField,
} from 'melp-design/components';
import { FC, useEffect } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form-latest';
import { useTranslation } from 'react-i18next';
import { usePredefinedToasts } from 'utils/Toast';
import { useRouteParams } from 'utils/useIdParam';
import { upper } from 'utils/general';
import { useAdminBrand, useAdminUpdateBrandContent } from 'store/admin-brands';
import { Translations } from 'types/Common';
import { ContentLanguage, contentLanguages } from 'types/general';
import { formatLanguage } from 'utils/format';

interface Values {
  languages: Array<Uppercase<ContentLanguage>>;
  translations: Translations<{
    description: string;
    shortDescription: string;
  }>;
}

export const AdminBrandContent: FC = () => {
  const { t, i18n } = useTranslation();
  const { id: brandId } = useRouteParams(ROUTES.admin.brands.content);
  const predefinedToasts = usePredefinedToasts();

  const { data: brand, isLoading } = useAdminBrand(brandId);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields, errors },
  } = useForm<Values>({
    defaultValues: {
      languages: [upper(APP.locales.default)],
      translations: [
        {
          language: upper(APP.locales.default),
          description: '',
          shortDescription: '',
        },
      ],
    },
  });
  const { fields: translationsFields, replace: replaceTranslations } =
    useFieldArray({ name: 'translations', control });

  useEffect(() => {
    if (brand) {
      reset({
        languages: brand.content.map((trans) => trans.language),
        translations: brand.content.map((trans) => ({
          ...trans,
          description: trans.description,
          shortDescription: trans.shortDescription,
        })),
      });
    }
  }, [brand, reset]);

  const { mutate: updateBrandContent, isLoading: isUpdating } =
    useAdminUpdateBrandContent();

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateBrandContent(
      {
        brandId,
        translations: values.translations,
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  if (isLoading) return <Loader />;
  if (!brand) return <NothingFoundAlert />;

  const isDirty = !!Object.keys(dirtyFields).length;
  const [languages, translations] = watch(['languages', 'translations']);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Panel title={t('benefitTemplates.templateContent')}>
            <Stack spacing={2}>
              <Controller
                name="languages"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <SelectInput
                    label={t('companies.supported_languages')}
                    name={name}
                    value={value}
                    onChange={(value) => {
                      const selectedLanguages = value as Array<
                        Uppercase<ContentLanguage>
                      >;
                      replaceTranslations(
                        selectedLanguages.map(
                          (language) =>
                            translations.find(
                              (t) => t.language === language,
                            ) ?? {
                              language,
                              description: '',
                              shortDescription: '',
                            },
                        ),
                      );
                      onChange(selectedLanguages);
                    }}
                    options={contentLanguages.map((l) => {
                      const language = l.toUpperCase();
                      return {
                        value: language,
                        label: formatLanguage(i18n.language, language),
                      };
                    })}
                    multiple
                    required
                    ref={ref}
                  />
                )}
              />

              {languages.length ? (
                <>
                  <MultiLanguageInputContainer
                    label={t('brands.shortDescription.label')}
                    info={t('common.max_char', { count: 200 })}
                    required
                    fields={translationsFields.map((field, i) => ({
                      id: field.id,
                      language: field.language,
                      component: (
                        <Controller
                          name={`translations.${i}.shortDescription`}
                          control={control}
                          rules={{
                            maxLength: {
                              value: 200,
                              message: t('common.maxValueValidationError', {
                                max: 200,
                              }),
                            },
                          }}
                          render={({
                            field: { name, value, onChange, ref },
                          }) => (
                            <TextField
                              name={name}
                              value={value}
                              onChange={onChange}
                              required
                              rows={2}
                              errorMessage={
                                errors.translations?.[i]?.shortDescription
                                  ?.message
                              }
                              ref={ref}
                            />
                          )}
                        />
                      ),
                    }))}
                  />

                  <MultiLanguageInputContainer
                    label={t('employees.details')}
                    info={t('common.max_char', { count: 1000 })}
                    required
                    fields={translationsFields.map((field, i) => ({
                      id: field.id,
                      language: field.language,
                      component: (
                        <Controller
                          name={`translations.${i}.description`}
                          control={control}
                          rules={{
                            maxLength: {
                              value: 1000,
                              message: t('common.maxValueValidationError', {
                                max: 1000,
                              }),
                            },
                          }}
                          render={({
                            field: { name, value, onChange, ref },
                          }) => (
                            <TextField
                              name={name}
                              value={value}
                              onChange={onChange}
                              required
                              rows={5}
                              errorMessage={
                                errors.translations?.[i]?.description?.message
                              }
                              ref={ref}
                            />
                          )}
                        />
                      ),
                    }))}
                  />
                </>
              ) : null}
            </Stack>
          </Panel>
        </Stack>

        <Footer visible={isDirty}>
          <Stack direction="row" justifyContent="end" gap="10px">
            <Button
              label={t('common.cancel')}
              variant="neutral-outline"
              onClick={() => {
                reset(undefined, { keepDirty: false });
              }}
            />
            <Button
              label={t('common.save')}
              type="submit"
              disabled={isUpdating}
            />
          </Stack>
        </Footer>
      </form>

      <DataLossPrompt when={isDirty && !isUpdating} />
    </>
  );
};
