import { Children, FC } from 'react';
import { Box, Stack } from '@mui/material';

const DetailsPageContent: FC = ({ children }) => {
  const [side, main] = Children.toArray(children);

  return (
    <Stack direction="row" gap={2}>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ position: 'sticky', top: '20px' }}>{side}</Box>
      </Box>
      <Box sx={{ width: '100%', overflowX: 'auto', pb: 8 }}>{main}</Box>
    </Stack>
  );
};

export default DetailsPageContent;
