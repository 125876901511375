import { FC } from 'react';
import { AdminBrandCardFees } from 'containers/admin';
import { AdminBrandCardLayout } from 'pages/atoms';

const AdminBrandCardFeesPage: FC = () => {
  return (
    <AdminBrandCardLayout>
      <AdminBrandCardFees />
    </AdminBrandCardLayout>
  );
};

export default AdminBrandCardFeesPage;
