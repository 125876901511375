import { z } from 'zod';
import { automationsFiltersSchema, ruleSchema } from './automations.schemas';

export const automationSubjects = ['employee'] as const;

export type AutomationSubject = (typeof automationSubjects)[number];

export const automationParameters = [
  'employeeBirthDate',
  'employeeAge',
  'employeeStartDate',
  'employeeWorkDuration',
  'employeeEndDate',
  'employeeGroup',
  'employeeCompany',
  'employeeDepartment',
  'employeeCustomCategory',
] as const;

export type AutomationParameter = (typeof automationParameters)[number];

export const automationOperators = [
  'isToday',
  'equals',
  'isBefore',
  'isAfter',
  'containsStrings',
  'notContainsStrings',
  'containsNumbers',
  'notContainsNumbers',
] as const;

export type AutomationOperator = (typeof automationOperators)[number];

export const automationProcessors = [
  'assignBenefit',
  'assignAlternative',
  'assignToGroup',
  'removeFromGroup',
] as const;

export type AutomationProcessor = (typeof automationProcessors)[number];

export interface AutomationRuleCondition {
  type: AutomationParameter;
  operator: AutomationOperator;
  value: string;
  rangeFrom: string;
  rangeTo: string;
  createdAt: string[];
}

export interface AutomationRuleAction {
  type: AutomationProcessor;
  value: string;
  createdAt: string;
}

export interface RuleValues {
  subject: AutomationSubject;
  conditions: Array<{
    type: AutomationParameter;
    operator: AutomationOperator;
    value: {
      primary: string[];
      secondary: string;
    };
  }>;
  actions: Array<{
    type: AutomationProcessor;
    value: string;
    meta: {
      isVisible: boolean;
      primary: string;
      secondary: string;
    };
  }>;
}

export type Rule = z.infer<typeof ruleSchema>;

export interface Automation {
  id: string;
  name: string;
  enabled: boolean;
  startsAt: string | null;
  endsAt: string | null;
  rule: RuleValues;
  createdAt: string;
  updatedAt: string | null;
  lastRunAt: string | null;
}

export type AutomationsFilters = z.infer<typeof automationsFiltersSchema>;
