import { IAutomation } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { loaders } from './automations.loaders';
import { Automation, Rule, RuleValues } from './automations.types';
import { ruleSchema } from './automations.schemas';

export const convertValuesToRule = (rule: RuleValues) => {
  const conditions = rule.conditions.map((condition) => {
    const common = { type: condition.type, operator: condition.operator };

    switch (condition.type) {
      case 'employeeAge':
        return {
          ...common,
          value: condition.value.primary.map((value) => Number(value)),
        };
      case 'employeeBirthDate':
        return {
          ...common,
        };
      case 'employeeCompany':
        return {
          ...common,
          value: condition.value.primary,
        };
      case 'employeeDepartment':
        return {
          ...common,
          value: condition.value.primary,
        };
      case 'employeeGroup':
        return {
          ...common,
          value: condition.value.primary,
        };
      case 'employeeCustomCategory':
        return {
          ...common,
          customCategoryId: condition.value.secondary,
          customCategoryValueIds: condition.value.primary,
        };
      case 'employeeStartDate':
        return {
          ...common,
        };
      case 'employeeWorkDuration':
        return {
          ...common,
          unit: condition.value.primary[0],
          value: Number(condition.value.secondary),
        };
      case 'employeeEndDate':
        return {
          ...common,
          unit: condition.value.primary[0],
          value: Number(condition.value.secondary),
        };

      default:
        return condition.type satisfies never;
    }
  });

  const actions = rule.actions.map((action) => {
    const common = { type: action.type };

    switch (action.type) {
      case 'assignBenefit':
        return {
          ...common,
          benefitId: action.value,
          ...(action.meta.isVisible
            ? {
                duration: Number(action.meta.primary),
                unit: action.meta.secondary,
              }
            : {}),
        };
      case 'assignAlternative':
        return {
          ...common,
          alternativeId: action.value,
        };
      case 'assignToGroup':
      case 'removeFromGroup':
        return {
          ...common,
          groupId: action.value,
        };

      default:
        return action.type satisfies never;
    }
  });

  return ruleSchema.parse({
    subject: {
      type: rule.subject,
    },
    conditions,
    action: actions[0],
  });
};

const convertRuleToValues = (rule: Rule): RuleValues => {
  return {
    subject: rule.subject.type,
    conditions: rule.conditions.map((condition) => {
      const common = { type: condition.type, operator: condition.operator };

      switch (condition.type) {
        case 'employeeAge':
          return {
            ...common,
            value: {
              primary: condition.value.map(String),
              secondary: '',
            },
          };
        case 'employeeBirthDate':
          return {
            ...common,
            value: { primary: [], secondary: '' },
          };
        case 'employeeCompany':
          return {
            ...common,
            value: { primary: condition.value, secondary: '' },
          };
        case 'employeeDepartment':
          return {
            ...common,
            value: { primary: condition.value, secondary: '' },
          };
        case 'employeeGroup':
          return {
            ...common,
            value: { primary: condition.value, secondary: '' },
          };
        case 'employeeCustomCategory':
          return {
            ...common,
            value: {
              primary: condition.customCategoryValueIds,
              secondary: condition.customCategoryId,
            },
          };
        case 'employeeStartDate':
          return {
            ...common,
            value: { primary: [], secondary: '' },
          };
        case 'employeeWorkDuration':
          return {
            ...common,
            value: {
              primary: [condition.unit],
              secondary: String(condition.value),
            },
          };
        case 'employeeEndDate':
          return {
            ...common,
            value: {
              primary: [condition.unit],
              secondary: String(condition.value),
            },
          };

        default:
          return condition satisfies never;
      }
    }),
    actions: [rule.action].map((action) => {
      const common = { type: action.type };

      switch (action.type) {
        case 'assignBenefit':
          return {
            ...common,
            value: action.benefitId,
            meta: {
              isVisible: !!action.duration || !!action.unit,
              primary: action.duration ? String(action.duration) : '',
              secondary: action.unit ?? '',
            },
          };
        case 'assignAlternative':
          return {
            ...common,
            value: action.alternativeId,
            meta: {
              isVisible: false,
              primary: '',
              secondary: '',
            },
          };
        case 'assignToGroup':
        case 'removeFromGroup':
          return {
            ...common,
            value: action.groupId,
            meta: {
              isVisible: false,
              primary: '',
              secondary: '',
            },
          };

        default:
          return action satisfies never;
      }
    }),
  };
};

const convertAutomation = (automation: IAutomation): Automation => {
  return {
    id: automation.id,
    name: automation.name,
    enabled: automation.enabled,
    startsAt: automation.startDate,
    endsAt: automation.endDate,
    rule: convertRuleToValues(JSON.parse(automation.ruleJSON)),
    createdAt: automation.createdAt,
    updatedAt: automation.updatedAt,
    lastRunAt: automation.lastRunAt,
  };
};

const convertAutomations = (automations: IAutomation[]): Automation[] => {
  return automations.map(convertAutomation);
};

export const converters = {
  getAutomations: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getAutomations>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertAutomations(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getAutomation: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getAutomation>>) => {
    return convertAutomation(data);
  },
};
