import { getAlpha3Codes } from 'i18n-iso-countries';
import { AppLanguage, cities } from 'types/general';

const alpha3 = Object.keys(getAlpha3Codes());

export const APP = {
  name: 'MELP',
  email: {
    info: 'info@melp.com',
  },
  layout: {
    container: {
      maxWidth: 1350,
    },
  },
  locales: {
    default: 'en',
  } satisfies { default: AppLanguage },
  countries: {
    alpha3,
    default: 'LTU',
  },
  cities,
  pagination: {
    defaults: {
      pageSize: [100, 150, 200],
    },
  },
  env: process.env.REACT_APP_ENV_NAME,
  host: {
    api: process.env.REACT_APP_API_URL,
  },
  cognito: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    poolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    webClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  },
  sentry: {
    dsn: 'https://f46106aa6e664b9b9893f2c19e8db899@o4504339175112704.ingest.sentry.io/4504367637397504',
  },
  hotjar: {
    siteId: Number(process.env.REACT_APP_HOTJAR_SITE_ID),
    version: Number(process.env.REACT_APP_HOTJAR_VERSION),
  },
} as const;
