import { Colors } from 'melp-design/style';
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { APP } from 'config';
import { getBorder } from '../../../constants/Style';

interface Props {
  children: ReactNode;
  visible?: boolean;
}

export const FOOTER_ID = 'app-footer';
export const FOOTER_HEIGHT = 70;

const Footer = ({ visible = true, children }: Props) => {
  if (!visible) return null;

  return (
    <Box
      id={FOOTER_ID}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: FOOTER_HEIGHT,
        backgroundColor: 'white',
        borderTop: getBorder(Colors.elementsGrey),
        padding: '5px 0',
        zIndex: 1,
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Box
        sx={(theme) => ({
          width: '100%',
          maxWidth: APP.layout.container.maxWidth,

          [theme.breakpoints.down('lg')]: {
            padding: '0 65px',
          },
        })}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Footer;
