import { Colors } from 'melp-design/style';
import { Box, FormControl, FormHelperText } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// TODO: refactor to use "react-phone-number-input" or smth similar, since current library is outdated
import ThirdPartyPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { forwardRef } from 'react';

const StyledPhoneInput = styled(ThirdPartyPhoneInput)({
  '&.react-tel-input': {
    fontFamily: "'Inter', sans-serif",
    height: 48,
    '& .form-control': {
      height: '100%',
      width: '100%',
      border: 0,
      transition: 'opacity 150ms ease-in-out',
      '&::placeholder': {
        color: Colors.grey,
      },
    },
    '& .flag-dropdown': {
      background: 'none',
      border: 'none',
      right: 0,
      pointerEvents: 'none',
      '& .selected-flag': {
        '&:hover': {
          background: 'none',
          border: 'none',
        },
      },
      '& .arrow': {
        display: 'none',
      },
    },
    '& .special-label': {
      display: 'block',
      padding: 0,
      left: '15px',
      top: '15px',
      background: 'transparent',
      fontSize: '14px',
      lineHeight: '1.4',
      color: Colors.grey,
      transformOrigin: '0px 0px',
      transition: 'transform 200ms ease-in-out',
    },
  },
});

interface Props extends Omit<PhoneInputProps, 'specialLabel' | 'inputProps'> {
  label?: string;
  required?: boolean;
  name?: string;
  errorMessage?: string;
}

export const PhoneInput = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, label, required, name, errorMessage, ...props }, ref) => {
    const { t } = useTranslation();
    const [shrink, setShrink] = useState(!!value);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!label) {
        return;
      }
      if (!!value && !shrink) {
        setShrink(true);
      }
      if (!value && shrink) {
        setShrink(false);
      }
      // React only to value changes to support the case when user clicks
      // the empty input and label should be shrinked.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const getInputElement = () => {
      return containerRef.current?.getElementsByClassName('form-control')[0] as
        | HTMLInputElement
        | undefined;
    };

    const shrinkLabel = () => {
      if (!label) {
        return;
      }
      if (!shrink) {
        setShrink(true);
      }
    };

    const expandLabel = () => {
      if (!label) {
        return;
      }
      const inputValue = getInputElement()?.value;
      if (!inputValue && shrink) {
        setShrink(false);
      }
    };

    const handleClick = () => {
      if (!label) {
        return;
      }
      shrinkLabel();
      const input = getInputElement();
      input?.focus();
    };

    return (
      <FormControl error={!!errorMessage}>
        <Box
          ref={containerRef}
          onClick={handleClick}
          onFocus={shrinkLabel}
          onBlur={expandLabel}
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: errorMessage ? Colors.red : Colors.elementsGrey,
            borderRadius: '6px',
            transition: 'border-color 100ms ease-in-out',

            '&:hover': {
              borderColor: Colors.greyHover,
            },
          }}
        >
          <StyledPhoneInput
            placeholder={t('placeholder.phone_number')}
            value={value}
            onChange={(newValue, country, ...rest) => {
              const adjustedValue =
                newValue && 'name' in country ? `+${newValue}` : newValue;
              onChange?.(adjustedValue, country, ...rest);
            }}
            disableDropdown
            specialLabel={label}
            inputProps={{
              required,
              name,
              // To prevent runtime errors when ref is null
              ref: ref ?? undefined,
            }}
            masks={{ nl: '.. .........' }}
            sx={{
              '&.react-tel-input': {
                '.form-control': {
                  padding: label ? '22px 15px 8px' : '15px',
                  opacity: !label ? 1 : shrink ? 1 : 0,
                },
                '& .special-label': {
                  display: label ? 'block' : 'none',
                  transform: shrink ? 'translate(0, -8px) scale(0.71)' : 'none',
                  '&::after': {
                    content: required ? '" *"' : '""',
                  },
                },
              },
            }}
            {...props}
          />
        </Box>
        {errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
      </FormControl>
    );
  },
);

PhoneInput.displayName = 'PhoneInput';
