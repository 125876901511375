import { APP } from 'config';
import { AppLanguage, appLanguages } from 'types/general';
import { includes } from 'utils/general';

export const formatMonth = (
  language: string,
  month: number,
  format: Intl.DateTimeFormatOptions['month'] = 'long',
) => {
  const locale: AppLanguage = includes(appLanguages, language)
    ? language
    : APP.locales.default;

  return new Intl.DateTimeFormat(locale, { month: format }).format(
    new Date(0, month),
  );
};
