import { AppLanguage, appLanguages } from 'types/general';
import { APP } from 'config';
import { getName } from 'i18n-iso-countries';
import { includes } from 'utils/general';

export const formatCountry = (language: string, alpha3: string): string => {
  const locale: AppLanguage = includes(appLanguages, language)
    ? language
    : APP.locales.default;

  return getName(alpha3, locale) || alpha3;
};
