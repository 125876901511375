import { FC } from 'react';
import {
  Link,
  ListTotal,
  Menu,
  Table,
  TableToolbar,
  TableWrapper,
  Typography,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { APP, ROUTES } from 'config';
import {
  internalShopInventoryFiltersSchema,
  useInternalShopInventoryItems,
} from 'store/internal-shop';
import {
  FilterButton,
  Filters,
  InternalShopItemsFilter,
} from 'components/filters';
import NumberRangeFilter from 'components/filters/NumberRangeFilter';
import { useModalContext } from 'store/modal';
import { InventoryUpdateModal, LowQuantityEditModal } from 'containers/shop';
import { useSearchParams } from 'utils/navigation';

export const ShopInternalInventoryList: FC = () => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  const { searchParams: filters, navigate } = useSearchParams(
    internalShopInventoryFiltersSchema,
  );

  const { data: inventoryItems, isLoading } =
    useInternalShopInventoryItems(filters);

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
        >
          <ListTotal total={inventoryItems?.total} />

          <Stack direction="row" alignItems="center" gap="10px">
            <Filters
              value={filters}
              initialValues={internalShopInventoryFiltersSchema.parse({})}
              onChange={(v) => navigate({ params: v })}
            >
              {({ value, initialValues, setFilter }) => (
                <>
                  <FilterButton
                    label={t('marketplaceOrders.itemName')}
                    value={value.items}
                    initialValue={initialValues.items}
                    onChange={(v) => setFilter('items', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <InternalShopItemsFilter
                        value={value}
                        onApplyFilter={applyFilter}
                        onClearFilter={clearFilter}
                        onCancel={close}
                      />
                    )}
                  </FilterButton>

                  <FilterButton
                    label={t('shop.variant.inventory')}
                    value={value.inventory}
                    initialValue={initialValues.inventory}
                    onChange={(v) => setFilter('inventory', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <NumberRangeFilter
                        value={value}
                        onApplyFilter={applyFilter}
                        onClearFilter={clearFilter}
                        onCancel={close}
                      />
                    )}
                  </FilterButton>
                </>
              )}
            </Filters>
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={inventoryItems?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('shop.variant.title'),
            cell: ({ row: { original: variant } }) => {
              return (
                <Link
                  label={variant.name || variant.id}
                  to={ROUTES.shop.internal.inventory.details.replace(
                    ':id',
                    variant.id,
                  )}
                />
              );
            },
          },
          {
            id: 'itemName',
            header: t('marketplaceOrders.itemName'),
            cell: ({ row: { original: variant } }) => {
              return (
                <Link
                  label={variant.itemName}
                  to={ROUTES.shop.internal.items.details.replace(
                    ':id',
                    variant.itemId,
                  )}
                />
              );
            },
          },
          {
            id: 'remaining',
            header: t('shop.variant.inventory'),
            cell: ({ row: { original: variant } }) => {
              return variant.trackInventory ? (
                <Typography
                  color={variant.remainingInventory < 1 ? 'error' : 'inherit'}
                >
                  {variant.remainingInventory}
                </Typography>
              ) : (
                '-'
              );
            },
            meta: { align: 'right' },
          },
          {
            id: 'lowQuantity',
            header: t('shop.lowQuantity.title'),
            cell: ({ row: { original: variant } }) => {
              return variant.trackInventory ? variant.lowQuantityAlert : '-';
            },
            meta: { info: t('shop.lowQuantity.info'), align: 'right' },
          },
          {
            id: 'actions',
            cell: ({ row: { original: variant } }) => {
              return (
                <Menu
                  label={t('common.actions')}
                  items={[
                    ...(variant.trackInventory
                      ? [
                          {
                            label: t('shop.actions.add_inventory'),
                            onClick: () => {
                              openModal({
                                component: InventoryUpdateModal,
                                props: { action: 'ADD', variantId: variant.id },
                              });
                            },
                          },
                          {
                            label: t('shop.actions.remove_inventory'),
                            onClick: () => {
                              openModal({
                                component: InventoryUpdateModal,
                                props: {
                                  action: 'REMOVE',
                                  variantId: variant.id,
                                },
                              });
                            },
                          },
                          {
                            label: t('shop.actions.edit_low_quantity'),
                            onClick: () => {
                              openModal({
                                component: LowQuantityEditModal,
                                props: {
                                  variantId: variant.id,
                                  initialLowQuantity: variant.lowQuantityAlert,
                                },
                              });
                            },
                          },
                        ]
                      : []),
                  ]}
                />
              );
            },
          },
        ]}
        pagination={
          inventoryItems && {
            count: inventoryItems.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </TableWrapper>
  );
};
