import { IRecognition, IRecognitionsSettings } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { loaders } from './recognitions.loaders';
import { Recognition, RecognitionsSettings } from './recognitions.types';

const convertRecognition = (recognition: IRecognition): Recognition => {
  return {
    id: recognition.id,
    status: recognition.approvalStatus,
    type: recognition.type,
    achievementTitle: recognition.achievementTitle,
    pointsReceived: recognition.pointsReceived,
    comment: recognition.comment,
    employee: recognition.employee
      ? {
          id: recognition.employee.id,
          firstName: recognition.employee.firstName,
          lastName: recognition.employee.lastName,
          fullName: [
            recognition.employee.firstName,
            recognition.employee.lastName,
          ]
            .filter(Boolean)
            .join(' '),
        }
      : null,
    sender: recognition.sender
      ? {
          id: recognition.sender.id,
          firstName: recognition.sender.firstName,
          lastName: recognition.sender.lastName,
          fullName: [recognition.sender.firstName, recognition.sender.lastName]
            .filter(Boolean)
            .join(' '),
        }
      : null,
    achievement: recognition.achievement
      ? {
          id: recognition.achievement.id,
          name: recognition.achievement.name,
          title: recognition.achievement.title,
        }
      : null,
    createdAt: recognition.createdAt,
  };
};

const convertRecognitions = (recognition: IRecognition[]): Recognition[] => {
  return recognition.map(convertRecognition);
};

const convertRecognitionsSettings = (
  settings: IRecognitionsSettings,
): RecognitionsSettings => {
  return {
    id: settings.id,
    pointName: settings.pointName,
    useMoney: settings.useMoney,
    currencyToPoint: settings.currencyToPoint,
    pointRewardPerRecognition: settings.pointRewardPerRecognition,
    limit: settings.limit,
    limitValue: settings.limitValue,
    limitPeriod: settings.limitPeriod,
    expirationEnabled: settings.hasPeriodicExpiration,
    expirationDay: settings.expirationDay ?? null,
    expirationMonth: settings.expirationMonth ?? null,
    expirationNotificationInDays: settings.expirationNotificationDays,
    expirationNotification: settings.expirationNotification,
    showPointsInFeed: settings.showPointsInFeed,
    translations: settings.instructions.map((translation) => ({
      language: translation.language,
      id: translation.id,
      description: translation.content,
    })),
  };
};

export const converters = {
  getRecognitions: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getRecognitions>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertRecognitions(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getRecognitionsSettings: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getRecognitionsSettings>>) => {
    return convertRecognitionsSettings(data);
  },
};
