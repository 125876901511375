import { APP } from 'config';
import { AppLanguage, appLanguages } from 'types/general';
import { capitalize, includes } from 'utils/general';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DisplayNames/DisplayNames
export const formatLanguage = (language: string, code: string): string => {
  const locale: AppLanguage = includes(appLanguages, language)
    ? language
    : APP.locales.default;

  const formated = new Intl.DisplayNames([locale], { type: 'language' }).of(
    code,
  );

  return capitalize(formated) || code;
};
