import { FC } from 'react';
import { Modal, FileInput } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps, useModalContext } from 'store/modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { useImportAdminOrdersBulkUpdate } from 'store/admin-orders';
import { usePredefinedToasts } from 'utils/Toast';
import { OrdersBulkUpdateErrorsModal } from '..';

interface Values {
  file: File | undefined;
}

interface Props extends ModalProps {}

export const OrdersBulkUpdateImportModal: FC<Props> = ({ closeModal }) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const { openModal } = useModalContext();

  const { control, handleSubmit } = useForm<Values>({
    defaultValues: { file: undefined },
    mode: 'onChange',
  });

  const { mutate: importBulkUpdate, status } = useImportAdminOrdersBulkUpdate();

  const onSubmit: SubmitHandler<Values> = (values) => {
    if (values.file) {
      importBulkUpdate(
        { file: values.file },
        {
          onSuccess: ({ data }) => {
            closeModal({ action: 'CLOSE' });

            if (data.success) {
              predefinedToasts.success.updated();
            } else {
              openModal({
                component: OrdersBulkUpdateErrorsModal,
                size: 'lg',
                props: {
                  errors: data.errorMessages.map((error) => ({
                    row: error.row,
                    field: error.column,
                    message: error.message,
                  })),
                },
              });
            }
          },
        },
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('global.actions.bulk_update')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('actions.import'),
            disabled: status === 'loading',
            type: 'submit',
          },
        ]}
      >
        <Controller
          name="file"
          control={control}
          render={({ field: { name, onChange, ref } }) => (
            <FileInput
              name={name}
              onChange={([file]) => {
                onChange(file);
              }}
              accept={['xls', 'xlsx']}
              maxFiles={1}
              required
              ref={ref}
            />
          )}
        />
      </Modal.Content>
    </form>
  );
};
