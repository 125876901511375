import { Stack } from '@mui/material';
import { ROUTES } from 'config';
import {
  Button,
  DataLossPrompt,
  Footer,
  LabeledValue,
  Loader,
  NothingFoundAlert,
  NumberInput,
  Panel,
} from 'melp-design/components';
import { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { useTranslation } from 'react-i18next';
import { usePredefinedToasts } from 'utils/Toast';
import { useRouteParams } from 'utils/useIdParam';
import {
  getFaceValueLabel,
  useAdminBrandCard,
  useAdminUpdateBrandCard,
} from 'store/admin-brand-cards';
import { formatNumber, formatPercents } from 'utils/format';
import { fromCents, toCents } from 'utils/general';

const calculateFinalPrice = (
  value: number,
  melpFeeEuro: number,
  melpFeePercentage: number,
) => value * (1 + melpFeePercentage / 100) + toCents(melpFeeEuro);

interface Values {
  melpFeeEuro: number;
  melpFeePercentage: number;
}

const INITIAL_VALUES: Values = {
  melpFeeEuro: NaN,
  melpFeePercentage: NaN,
};

export const AdminBrandCardFees: FC = () => {
  const { t, i18n } = useTranslation();
  const { id: cardId } = useRouteParams(ROUTES.admin.cards.settings);
  const predefinedToasts = usePredefinedToasts();

  const { data: card, isLoading } = useAdminBrandCard(cardId);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields },
  } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });

  useEffect(() => {
    if (card) {
      reset({
        melpFeeEuro: fromCents(card.melpFeeEuro),
        melpFeePercentage: card.melpFeePercentage * 100,
      });
    }
  }, [card, reset]);

  const { mutate: updateCard, isLoading: isUpdating } =
    useAdminUpdateBrandCard();

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateCard(
      {
        cardId,
        data: {
          melpFeeEuro: toCents(values.melpFeeEuro),
          melpFeePercentage: values.melpFeePercentage,
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  if (isLoading) return <Loader />;
  if (!card) return <NothingFoundAlert />;

  const isDirty = !!Object.keys(dirtyFields).length;
  const [melpFeeEuro, melpFeePercentage] = watch([
    'melpFeeEuro',
    'melpFeePercentage',
  ]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Panel title={t('cards.fees.provider.title')}>
            <Stack spacing={2}>
              <LabeledValue
                label={t('cards.fee.label')}
                value={formatPercents(
                  i18n.language,
                  card.purchasePriceDiscountPercentage < 0
                    ? Math.abs(card.purchasePriceDiscountPercentage)
                    : 0,
                )}
              />

              <LabeledValue
                label={t('marketplaceItems.discountPercentage')}
                value={formatPercents(
                  i18n.language,
                  card.purchasePriceDiscountPercentage >= 0
                    ? card.purchasePriceDiscountPercentage
                    : 0,
                )}
              />

              <LabeledValue
                label={t('cards.fixedFee.label')}
                value={formatNumber(
                  i18n.language,
                  card.purchasePriceDiscountFee,
                )}
              />

              <LabeledValue
                label={t('marketplaceItems.currency')}
                value={card.purchasePriceCurrency}
              />
            </Stack>
          </Panel>

          <Panel title={t('cards.fees.melp.title')}>
            <Stack spacing={2}>
              <Controller
                name="melpFeePercentage"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <NumberInput
                    label={t('cards.fee.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    key={value}
                    InputProps={{
                      endAdornment: '%',
                    }}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="melpFeeEuro"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <NumberInput
                    label={t('cards.fixedFee.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    key={value}
                    ref={ref}
                  />
                )}
              />

              <LabeledValue
                label={t('marketplaceItems.currency')}
                value="EUR"
              />

              <LabeledValue
                label={t('cards.finalPrice.label')}
                value={getFaceValueLabel({
                  ...card,
                  faceValues: card.faceValues.map((value) =>
                    calculateFinalPrice(value, melpFeeEuro, melpFeePercentage),
                  ),
                  faceValueMin: calculateFinalPrice(
                    card.faceValueMin,
                    melpFeeEuro,
                    melpFeePercentage,
                  ),
                  faceValueMax: calculateFinalPrice(
                    card.faceValueMax,
                    melpFeeEuro,
                    melpFeePercentage,
                  ),
                  language: i18n.language,
                })}
              />
            </Stack>
          </Panel>
        </Stack>

        <Footer visible={isDirty}>
          <Stack direction="row" justifyContent="end" gap="10px">
            <Button
              label={t('common.cancel')}
              variant="neutral-outline"
              onClick={() => {
                reset(undefined, { keepDirty: false });
              }}
            />
            <Button
              label={t('common.save')}
              type="submit"
              disabled={isUpdating}
            />
          </Stack>
        </Footer>
      </form>

      <DataLossPrompt when={isDirty && !isUpdating} />
    </>
  );
};
