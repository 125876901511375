import { Stack } from '@mui/material';
import { ROUTES } from 'config';
import {
  AutocompleteInput,
  Button,
  DataLossPrompt,
  FileInput,
  Footer,
  ImageList,
  Loader,
  NothingFoundAlert,
  NumberInput,
  Panel,
  SelectInput,
  TextField,
} from 'melp-design/components';
import { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { useTranslation } from 'react-i18next';
import { usePredefinedToasts } from 'utils/Toast';
import { useRouteParams } from 'utils/useIdParam';
import {
  AdminBrandStatus,
  adminBrandStatuses,
  AdminBrandVariant,
  adminBrandVariants,
  useAdminBrand,
  useAdminUpdateBrand,
} from 'store/admin-brands';
import { shopItemCategories, ShopItemCategory } from 'types/MarketplaceItems';
import { useUploadFile } from 'store/files';
import { Delete } from '@mui/icons-material';

interface Values {
  name: string;
  slug: string;
  status: AdminBrandStatus;
  type: AdminBrandVariant;
  primaryCategory: ShopItemCategory;
  secondaryCategory: ShopItemCategory | '';
  priorityIndex: number;
  searchTags: string;
  imageId: string;
}

export const AdminBrandSettings: FC = () => {
  const { t } = useTranslation();
  const { id: brandId } = useRouteParams(ROUTES.admin.brands.settings);
  const predefinedToasts = usePredefinedToasts();

  const { data: brand, isLoading } = useAdminBrand(brandId);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields },
  } = useForm<Values>({
    defaultValues: {
      name: '',
      slug: '',
      status: 'enabled',
      type: 'gift_card',
      primaryCategory: 'entertainment',
      secondaryCategory: '',
      priorityIndex: 1,
      searchTags: '',
      imageId: '',
    },
  });

  useEffect(() => {
    if (brand) {
      reset({
        name: brand.name,
        slug: brand.slug,
        status: brand.status,
        type: brand.variant,
        primaryCategory: brand.primaryCategory,
        secondaryCategory: brand.secondaryCategory,
        priorityIndex: brand.priorityIndex,
        searchTags: brand.searchTags,
        imageId: brand.logo?.id,
      });
    }
  }, [brand, reset]);

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile();
  const { mutate: updateBrand, isLoading: isUpdating } = useAdminUpdateBrand();

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateBrand(
      {
        brandId,
        data: {
          name: values.name,
          slug: values.slug,
          type: values.type,
          status: values.status,
          primaryCategory: values.primaryCategory,
          secondaryCategory: values.secondaryCategory,
          priorityIndex: values.priorityIndex,
          searchTags: values.searchTags,
          logoId: values.imageId,
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  if (isLoading) return <Loader />;
  if (!brand) return <NothingFoundAlert />;

  const isDirty = !!Object.keys(dirtyFields).length;
  const [imageId] = watch(['imageId']);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Panel title={t('common.settings')}>
            <Stack spacing={2}>
              <Controller
                name="status"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SelectInput
                      label={t('common.status')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      options={adminBrandStatuses.map((status) => ({
                        label: t(`status.${status}`),
                        value: status,
                      }))}
                      required
                      ref={ref}
                    />
                  );
                }}
              />

              <Controller
                name="name"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('common.name')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="slug"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('common.slug')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="type"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SelectInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      label={t('integrations.type')}
                      options={adminBrandVariants.map((variant) => ({
                        label: t(`brands.variants.${variant}`),
                        value: variant,
                      }))}
                      required
                      ref={ref}
                    />
                  );
                }}
              />

              <Controller
                name="primaryCategory"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <AutocompleteInput
                    label={t('brands.primary_category.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    options={shopItemCategories.map((category) => ({
                      label: t(`marketplaceItems.itemCategory-${category}`),
                      value: category,
                    }))}
                    multiple={false}
                    required
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="secondaryCategory"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <AutocompleteInput
                    label={t('brands.secondary_category.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    options={shopItemCategories.map((category) => ({
                      label: t(`marketplaceItems.itemCategory-${category}`),
                      value: category,
                    }))}
                    multiple={false}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="priorityIndex"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <NumberInput
                    label={t('common.priority.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    type="integer"
                    key={value}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="searchTags"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('shop.search_tags.label')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    rows={5}
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="imageId"
                control={control}
                render={({ field: { name, onChange, ref } }) => {
                  return imageId ? (
                    <ImageList
                      items={[
                        {
                          imageId,
                          actions: [
                            {
                              icon: Delete,
                              variant: 'neutral-outline',
                              onClick: () => onChange(''),
                            },
                          ],
                        },
                      ]}
                    />
                  ) : (
                    <FileInput
                      name={name}
                      onChange={([file]) => {
                        if (file) {
                          uploadFile(
                            { file },
                            {
                              onSuccess: ({ data }) => {
                                onChange(data.id);
                              },
                            },
                          );
                        } else {
                          onChange('');
                        }
                      }}
                      disabled={isUploading}
                      maxFiles={1}
                      previewSelectedFiles={false}
                      accept={['jpg', 'jpeg', 'png', 'gif', 'webp']}
                      required
                      ref={ref}
                    />
                  );
                }}
              />
            </Stack>
          </Panel>
        </Stack>

        <Footer visible={isDirty}>
          <Stack direction="row" justifyContent="end" gap="10px">
            <Button
              label={t('common.cancel')}
              variant="neutral-outline"
              onClick={() => {
                reset(undefined, { keepDirty: false });
              }}
            />
            <Button
              label={t('common.save')}
              type="submit"
              disabled={isUpdating}
            />
          </Stack>
        </Footer>
      </form>

      <DataLossPrompt when={isDirty && !isUpdating} />
    </>
  );
};
