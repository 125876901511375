import { Colors } from 'melp-design/style';
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/system';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { insertIntoInput } from '../../../utils/insertIntoInput';
import EmojiPicker from '../EmojiPicker/EmojiPicker';

const useSecureText = () => {
  const [isSecure, setIsSecure] = useState(true);

  return {
    InputProps: {
      sx: {
        '-webkit-text-security': isSecure ? 'disc' : 'none',
      },
      autoComplete: 'nope',
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setIsSecure((current) => !current)}
            size="large"
          >
            {isSecure ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    },
  };
};

const StyledTextField = styled(MuiTextField)(({ label }) => ({
  '& .MuiInputLabel-root': {
    color: Colors.grey,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.4,
    transform: 'translate(15px, 15px) scale(1)',

    '&.MuiInputLabel-shrink': {
      transform: 'translate(15px, 8px) scale(0.71)',
    },
    '&.Mui-focused': {
      color: Colors.grey,
    },
    '&.Mui-error': {
      color: Colors.grey,
    },
    '& .MuiInputLabel-asterisk': {
      color: 'inherit',
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-multiline': {
      padding: !!label ? '22px 15px 8px' : '15px',
    },
    '& .MuiOutlinedInput-input': {
      padding: !!label ? '22px 15px 8px' : '15px',
      color: Colors.black,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.4,
      height: 'auto',

      '&.MuiInputBase-inputMultiline': {
        padding: 0,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 6,

      '& legend': {
        maxWidth: '0.01px',
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.greyHover,
    },
  },
}));

interface AdditionalProps {
  emoji?: boolean;
  secure?: boolean;
  readonly?: boolean;
  rows?: number;
  errorMessage?: string;
  infoMessage?: string;
}

export type TextFieldProps = MuiTextFieldProps & AdditionalProps;

export const TextField = React.forwardRef(
  (
    {
      emoji,
      secure,
      rows,
      readonly,
      errorMessage,
      infoMessage,
      ...props
    }: TextFieldProps,
    ref,
  ) => {
    const innerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => innerRef.current);

    const { InputProps: SecureTextInputProps } = useSecureText();

    const emojiPicker = emoji ? (
      <EmojiPicker
        disabled={props.disabled}
        onSelect={(emoji) => {
          if (!innerRef.current) {
            throw new Error('Root element is missing');
          }
          const inputEl: HTMLInputElement | HTMLTextAreaElement =
            innerRef.current.getElementsByTagName(
              rows ? 'textarea' : 'input',
            )[0];
          insertIntoInput(inputEl, emoji);
        }}
      />
    ) : undefined;

    return (
      <StyledTextField
        ref={innerRef}
        InputProps={{
          ...(secure
            ? SecureTextInputProps
            : {
                endAdornment: emojiPicker,
              }),
          readOnly: readonly,
        }}
        InputLabelProps={{ sx: { whiteSpace: 'nowrap' } }}
        FormHelperTextProps={{
          sx: { fontSize: 12, letterSpacing: 'inherit' },
        }}
        {...(rows ? { multiline: true, minRows: rows } : {})}
        error={!!errorMessage}
        helperText={errorMessage || infoMessage}
        {...props}
      />
    );
  },
);

TextField.displayName = 'TextField';
