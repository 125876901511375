import { FC } from 'react';
import { AdminBrandCardSettings } from 'containers/admin';
import { AdminBrandCardLayout } from 'pages/atoms';

const AdminBrandCardSettingsPage: FC = () => {
  return (
    <AdminBrandCardLayout>
      <AdminBrandCardSettings />
    </AdminBrandCardLayout>
  );
};

export default AdminBrandCardSettingsPage;
