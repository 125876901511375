import { FC } from 'react';
import { Stack } from '@mui/material';
import { FilterButton, Filters, SelectFilter } from 'components/filters';
import TextFilter from 'components/filters/TextFilter';
import { ROUTES } from 'config';
import { AddSupplier } from 'containers/Suppliers/Suppliers/AddSupplier';
import {
  Link,
  ListPageHeader,
  ListTotal,
  Table,
  Typography,
} from 'melp-design/components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  adminSuppliersFiltersSchema,
  useAdminSuppliersStatistics,
} from 'store/admin-suppliers';
import { COLOR_BY_STATUS, supplierStatuses } from 'store/suppliers';
import { useSearchParams } from 'utils/navigation';

export const AdminSuppliersLit: FC = () => {
  const { t } = useTranslation();

  const { searchParams: filters, navigate } = useSearchParams(
    adminSuppliersFiltersSchema,
  );

  const { data: suppliers, isLoading } = useAdminSuppliersStatistics(filters);

  return (
    <>
      <ListPageHeader
        title={t('menu.suppliers')}
        sideContent={<AddSupplier />}
        toolbarContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={suppliers?.total} />
            <Stack direction="row" alignItems="center" gap="10px">
              <Filters
                value={filters}
                initialValues={adminSuppliersFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('common.status')}
                      value={value.status}
                      initialValue={initialValues.status}
                      onChange={(v) => setFilter('status', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="status"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          searchable={false}
                          options={supplierStatuses.map((status) => ({
                            key: status,
                            label: t(`status.${status}`),
                          }))}
                          multiple={false}
                        />
                      )}
                    </FilterButton>

                    <TextFilter
                      value={value.name}
                      onApplyFilter={(v) => setFilter('name', v)}
                      placeholder={t('common.search')}
                    />
                  </>
                )}
              </Filters>
            </Stack>
          </Stack>
        }
      />

      <Table
        data={suppliers?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('marketplaceSuppliers.name'),
            cell: ({ row: { original: supplier } }) => (
              <Link
                label={[
                  supplier.name,
                  supplier.isParentCompany ? `(${t('common.private')})` : null,
                ]
                  .filter(Boolean)
                  .join(' ')}
                to={ROUTES.admin.suppliers.details.replace(':id', supplier.id)}
              />
            ),
          },
          {
            id: 'createdAt',
            header: t('marketplaceSuppliers.creationDate'),
            cell: ({ row: { original: supplier } }) =>
              moment(supplier.createdAt).format('l'),
          },
          {
            id: 'items',
            header: t('marketplaceSuppliers.activeItems'),
            cell: ({ row: { original: supplier } }) => supplier.items,
          },
          {
            id: 'orders',
            header: t('marketplaceSuppliers.completedOrders'),
            cell: ({ row: { original: supplier } }) => supplier.orders,
          },
          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: supplier } }) => (
              <Typography color={COLOR_BY_STATUS[supplier.status]}>
                {t(`status.${supplier.status}`)}
              </Typography>
            ),
          },
        ]}
      />
    </>
  );
};
