import { AppLanguage, appLanguages } from 'types/general';
import { Currency } from 'types/general';
import { APP } from 'config';
import { includes } from 'utils/general';

export const formatCurrency = (
  language: string,
  amount: number,
  currency: Currency = 'EUR',
): string => {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currencyDisplay: 'code',
    currency,
  };

  const locale: AppLanguage = includes(appLanguages, language)
    ? language
    : APP.locales.default;

  return Intl.NumberFormat(locale, options).format(amount / 100);
};
