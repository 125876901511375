import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IAdminOrder, IFileImport } from 'types/income';
import { OrderStatus } from 'store/orders';
import { RedeemMethods } from 'store/admin-shop-vouchers';
import { exportXlsxConfig, getFormData } from 'utils/general';
import { AdminOrdersFilters, SearchFilterName } from './admin-orders.types';

const getFilters = (filters: Partial<AdminOrdersFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  filter: {
    status: filters.status,
    orderNr: filters.orderNrs?.filter(Boolean),
    orderId: filters.orderIds,
    parentCompany: filters.parentCompanies,
    company: filters.companies,
    itemId: filters.sku,
    itemName: filters.items,
    creationDateFrom: filters.createdAt?.from,
    creationDateTo: filters.createdAt?.to,
  },
  sort: filters.sort
    ? `${filters.sort.order === 'desc' ? '-' : ''}${filters.sort.columnKey}`
    : undefined,
});

interface GetAdminOrders {
  filters: Partial<AdminOrdersFilters>;
}

interface GetAdminOrder {
  orderId: string;
}

interface UpdateAdminOrder {
  orderId: string;
  data: {
    couponFile: string;
    status: OrderStatus;
    commentForAdmin: string;
    commentForEmployee: string;
    purchasePrice: number;
  } & RedeemMethods;
}

interface ExportXls {
  filters: Partial<AdminOrdersFilters>;
}

interface GetFilterValues {
  filterName: SearchFilterName;
  search: string;
}

interface BulkUpdate {
  ids: string[];
  status: OrderStatus;
}

interface ImportBulkUpdate {
  file: File;
}

export const loaders = {
  getAdminOrders: ({ filters }: GetAdminOrders) => {
    return api<TableDataResponse<IAdminOrder>>({
      url: Endpoints.melpAdmin.orders.root,
      params: getFilters(filters),
    });
  },
  getAdminOrder: ({ orderId }: GetAdminOrder) =>
    api<IAdminOrder>({
      url: Endpoints.melpAdmin.orders.byId.replace(':orderId', orderId),
    }),
  updateAdminOrder: ({ orderId, data }: UpdateAdminOrder) => {
    return api({
      url: Endpoints.melpAdmin.orders.byId.replace(':orderId', orderId),
      method: 'patch',
      data: {
        couponFileId: data.couponFile || null,
        couponCode: data.code,
        barcode: data.barCode,
        pin: data.pin,
        serial: data.serial,
        qrcode: data.qrCode,
        url: data.url,
        status: data.status,
        commentForAdmin: data.commentForAdmin,
        commentForEmployee: data.commentForEmployee,
        purchasePrice: data.purchasePrice,
      },
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    return api({
      url: Endpoints.melpAdmin.orders.export.xls,
      params: {
        filter: getFilters(filters).filter,
        sort: getFilters(filters).sort,
      },
      ...exportXlsxConfig,
    });
  },
  getFilterValues: ({ filterName, search }: GetFilterValues) => {
    return api<string[]>({
      url: `${Endpoints.melpAdmin.orders.filterValues}/${filterName}`,
      params: {
        lookupValue: search,
      },
    });
  },
  bulkUpdate: ({ ids, status }: BulkUpdate) => {
    return api({
      url: Endpoints.melpAdmin.orders.bulk.update,
      method: 'patch',
      data: { ids, status },
    });
  },
  downloadBulkUpdateTemplate: () => {
    return api({
      url: Endpoints.melpAdmin.orders.bulk.xls,
      ...exportXlsxConfig,
    });
  },
  importBulkUpdate: ({ file }: ImportBulkUpdate) => {
    return api<IFileImport>({
      url: Endpoints.melpAdmin.orders.bulk.xls,
      method: 'post',
      data: getFormData([{ field: 'file', value: file }]),
    });
  },
};
