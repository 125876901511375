import { formatNumber, formatList } from 'utils/format';
import { AdminBrandCard } from './admin-brand-cards.types';

const DISPLAY_RANGE_FOR_FIXED_PRICES = false;

export const getFaceValueLabel = ({
  priceType,
  faceValues,
  faceValueMin,
  faceValueMax,
  language,
}: Pick<
  AdminBrandCard,
  | 'priceType'
  | 'faceValues'
  | 'faceValueMin'
  | 'faceValueMax'
  | 'faceValueCurrency'
> & { language: string }) => {
  switch (priceType) {
    case 'fixed':
      if (DISPLAY_RANGE_FOR_FIXED_PRICES) {
        if (!faceValues.length) {
          return '-';
        }

        if (faceValues.length === 1) {
          return formatNumber(language, faceValues[0] / 100);
        }

        return [
          formatNumber(language, Math.min(...faceValues) / 100),
          formatNumber(language, Math.max(...faceValues) / 100),
        ].join(' - ');
      }

      return formatList(
        language,
        faceValues.map((price) => formatNumber(language, price / 100)),
      );
    case 'range':
      return [
        formatNumber(language, faceValueMin / 100),
        formatNumber(language, faceValueMax / 100),
      ].join(' - ');
    default:
      return priceType satisfies never;
  }
};
