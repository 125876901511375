import { FC, PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, Link as MuiLink } from '@mui/material';
import {
  DetailsPageContent,
  DetailsPageHeader,
  Menu,
  SideMenu,
} from 'melp-design/components';
import { ROUTES } from 'config';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useRouteParams } from 'utils/useIdParam';
import {
  useAutomation,
  useCreateAutomation,
  useExecuteAutomation,
  useRemoveAutomation,
} from 'store/automations';
import { toast } from 'react-toastify';
import { useConfirm } from 'store/confirm';
import { usePredefinedToasts } from 'utils/Toast';

export const AutomationLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { id: automationId } = useRouteParams(ROUTES.automations.details);
  const { confirmation } = useConfirm();
  const predefinedToasts = usePredefinedToasts();
  const history = useHistory();

  const { data: automation } = useAutomation(automationId);
  const { mutate: createAutomation, isLoading: isCreating } =
    useCreateAutomation();
  const { mutate: executeAutomation, isLoading: isExecuting } =
    useExecuteAutomation();
  const { mutate: removeAutomation, isLoading: isRemoving } =
    useRemoveAutomation();

  return (
    <Stack>
      <DetailsPageHeader
        backTo={ROUTES.automations.list}
        title={automation ? automation.name : t('automation.addRule')}
        side={
          automation ? (
            <Menu
              label={t('common.actions')}
              items={[
                {
                  label: t('global.actions.execute'),
                  onClick: () => {
                    executeAutomation(
                      { automationId: automation.id },
                      {
                        onSuccess: ({ data: { subjectCount } }) => {
                          toast.success(
                            t('automations.execute.success', {
                              name: automation.name,
                              count: subjectCount,
                              subject: t(
                                `automations.subject.${automation.rule.subject}`,
                              ).toLowerCase(),
                              interpolation: { escapeValue: false },
                            }),
                          );
                        },
                      },
                    );
                  },
                  disabled: isExecuting,
                },
                {
                  label: t('actions.make_copy'),
                  onClick: async () => {
                    const { confirmed } = await confirmation(
                      <Trans
                        i18nKey="automations.copy.prompt"
                        values={{
                          name: automation.name,
                        }}
                        components={[
                          <MuiLink
                            component="a"
                            href={ROUTES.automations.details.replace(
                              ':id',
                              automation.id,
                            )}
                            target="_blank"
                            rel="noreferrer"
                            sx={{ color: 'inherit' }}
                            underline="always"
                            key="automation"
                          >
                            placeholder
                          </MuiLink>,
                        ]}
                      />,
                    );

                    if (confirmed) {
                      createAutomation(
                        {
                          name: `(${t('common.copy')}) ${automation.name}`,
                          enabled: automation.enabled,
                          startDate: automation.startsAt,
                          endDate: automation.endsAt,
                          rule: automation.rule,
                        },
                        {
                          onSuccess: ({ data: { id } }) => {
                            predefinedToasts.success.added();
                            history.push(
                              ROUTES.automations.details.replace(':id', id),
                            );
                          },
                        },
                      );
                    }
                  },
                  disabled: isCreating,
                },
                {
                  label: t('actions.delete'),
                  onClick: async () => {
                    const { confirmed } = await confirmation(
                      t('common.areYouSureToDelete', {
                        title: automation.name,
                      }),
                    );

                    if (confirmed) {
                      removeAutomation(
                        { automationId: automation.id },
                        {
                          onSuccess: () => {
                            predefinedToasts.success.deleted();
                            history.push(ROUTES.automations.list);
                          },
                        },
                      );
                    }
                  },
                  disabled: isRemoving,
                },
              ]}
            />
          ) : null
        }
      />

      <DetailsPageContent>
        <SideMenu
          tabs={[
            {
              key: 'details',
              label: t('common.settings'),
              url: ROUTES.automations.details.replace(':id', automationId),
              isActive: path === ROUTES.automations.details,
              disabled: !automation,
            },
          ]}
        />
        {children}
      </DetailsPageContent>
    </Stack>
  );
};
