import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import {
  IBaseEmployee,
  IEmployee,
  IEmployeeExpense,
  IEmployeeWalletBenefit,
  IEmployeeWalletRecognitions,
} from 'types/income';
import { TableDataResponse } from 'types/Table';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { RECOGNITION_SHOP_ID } from 'store/recognitions';
import { ExpenseStatus } from 'store/expenses';
import {
  exportXlsxConfig,
  getBinaryValue,
  getSorting,
  toCents,
} from 'utils/general';
import {
  EmployeeExpensesFilters,
  EmployeeJobTitlesFilters,
  EmployeeWalletBenefitsFilters,
  EmployeesFilters,
  EmploymentType,
} from './employees.types';

const getFilters = (filters: Partial<EmployeesFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  ...getSorting(filters.sort),
  filter: {
    name: filters.name || undefined,
    employeeIds: filters.employees,
    employeeGroupIds: filters.employeeGroups,
    employmentStatus: filters.status,
    hireDateFrom: filters.hiredAt?.from,
    hireDateTo: filters.hiredAt?.to,
    appUser: getBinaryValue(filters.isAppUser),
    birthdaysSoon: getBinaryValue(filters.birthdaySoon),
    workAnniversarySoon: getBinaryValue(filters.workAnniversarySoon),
    companyIds: filters.companies,
    departmentIds: filters.departments,
    customCategoryId: filters.customCategory || undefined,
    customCategoryValueIds: filters.customCategoryValues,
  },
});

interface GetEmployees {
  filters: Partial<EmployeesFilters>;
}

interface GetEmployee {
  employeeId: string;
}

interface CreateEmployee {
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  personalCode?: string;
  companyId: string;
  startDate: string;
  startDateTZ: number;
}

interface UpdateEmployee {
  employeeId: string;
  data: Partial<{
    firstName: string;
    lastName: string;
    personalCode: string;
    phone: string | null;
    email: string | null;
    dateOfBirth: string | null;
    internalComment: string;
    companyId: string;
    departmentId: string;
    jobTitle: string;
    employeeId: string;
    hireDate: string;
    capacity: EmploymentType;
    categories: Array<{
      employeeCategoryId: string;
      employeeCategoryValueId: string;
    }>;
    temporaryEmployment: boolean;
    startDate: string | null;
    endDate: string | null;
    startDateTZ: number;
    endDateTZ: number;
    employmentDate: string | null;
    employmentDateTZ: number;
    endTime: string | null;
    startTime: string | null;
  }>;
}

interface BulkUpdateEmployees {
  employees: string[];
  data: Partial<{
    startDate: string;
    startDateTZ: number;
    endDate: string;
    endDateTZ: number;
    hireDate: string;
  }>;
}

interface SendInvitations {
  employees: string[];
}

interface DeleteEmployee {
  employeeId: string;
}

interface GetJobTitles {
  filters: Partial<EmployeeJobTitlesFilters>;
}

interface GetEmployeeExpenses {
  employeeId: string;
  filters: Partial<EmployeeExpensesFilters>;
}

interface CreateExpense {
  employeeId: string;
  expense: {
    date: string;
    status: ExpenseStatus;
    benefits: string[];
    recognition: boolean;
    shopCategories: ShopItemCategory[];
    amount: number;
    description: string;
  };
}

interface GetWalletBenefits {
  employeeId: string;
  filters: Partial<EmployeeWalletBenefitsFilters>;
}

interface GetWalletRecognitions {
  employeeId: string;
}

interface ExportXls {
  filters: Partial<EmployeesFilters>;
}

export const loaders = {
  getEmployees: ({ filters }: GetEmployees) => {
    return api<TableDataResponse<IBaseEmployee>>({
      url: Endpoints.clientAdmin.employees.root,
      params: {
        ...getFilters(filters),
      },
    });
  },
  getEmployee: ({ employeeId }: GetEmployee) => {
    return api<IEmployee>({
      url: Endpoints.clientAdmin.employees.byId.root.replace(
        ':employeeId',
        employeeId,
      ),
    });
  },
  createEmployee: (data: CreateEmployee) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.employees.create,
      data,
      method: 'post',
    });
  },
  updateEmployee: ({ employeeId, data }: UpdateEmployee) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.employees.byId.root.replace(
        ':employeeId',
        employeeId,
      ),
      data,
      method: 'patch',
    });
  },
  bulkUpdateEmployees: ({ employees, data }: BulkUpdateEmployees) => {
    return api({
      url: Endpoints.clientAdmin.employees.bulk.update,
      data: {
        employeeIds: employees,
        employee: data,
      },
      method: 'patch',
    });
  },
  sendInvitations: ({ employees }: SendInvitations) => {
    return api({
      url: Endpoints.clientAdmin.employees.invitations,
      data: {
        employeeIds: employees,
      },
      method: 'post',
    });
  },
  deleteEmployee: ({ employeeId }: DeleteEmployee) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.employees.byId.root.replace(
        ':employeeId',
        employeeId,
      ),
      method: 'delete',
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    const { page, pageSize, sorting, direction, ...restFilters } =
      getFilters(filters);
    return api({
      url: Endpoints.clientAdmin.employees.export.xls,
      params: {
        ...restFilters,
      },
      ...exportXlsxConfig,
    });
  },
  getJobTitles: ({ filters }: GetJobTitles) => {
    return api<string[]>({
      url: Endpoints.clientAdmin.employees.jobTitles,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        search: filters.search || undefined,
      },
    });
  },
  getExpenses: ({ employeeId, filters }: GetEmployeeExpenses) => {
    return api<TableDataResponse<IEmployeeExpense>>({
      url: Endpoints.clientAdmin.employees.byId.expenses.root.replace(
        ':employeeId',
        employeeId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          dateFrom: filters.date?.from,
          dateTo: filters.date?.to,
          itemCategory: filters.categories,
          expenseStatus: filters.status,
          benefitId:
            filters.source?.filter(
              (source) => source !== RECOGNITION_SHOP_ID,
            ) ?? [],
          recognition:
            filters.source?.includes(RECOGNITION_SHOP_ID) || undefined,
        },
      },
    });
  },
  createExpense: ({ employeeId, expense }: CreateExpense) => {
    return api({
      url: Endpoints.clientAdmin.employees.byId.expenses.root.replace(
        ':employeeId',
        employeeId,
      ),
      method: 'post',
      data: {
        date: expense.date,
        status: expense.status,
        sourceBenefitIds: expense.benefits,
        sourceRecognition: expense.recognition,
        marketplaceItemCategories: expense.shopCategories,
        expense: toCents(expense.amount),
        description: expense.description,
      },
    });
  },
  getWalletBenefits: ({ employeeId, filters }: GetWalletBenefits) => {
    return api<TableDataResponse<IEmployeeWalletBenefit>>({
      url: Endpoints.clientAdmin.employees.byId.walletBenefits.root.replace(
        ':employeeId',
        employeeId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          name: filters.benefits?.filter(Boolean),
          status: filters.status,
        },
      },
    });
  },
  getWalletRecognitions: ({ employeeId }: GetWalletRecognitions) => {
    return api<IEmployeeWalletRecognitions>({
      url: Endpoints.clientAdmin.employees.byId.walletRecognitions.root.replace(
        ':employeeId',
        employeeId,
      ),
    });
  },
};
