import { AppLanguage, appLanguages } from 'types/general';
import { APP } from 'config';
import { includes } from 'utils/general';

export const formatNumber = (language: string, amount: number): string => {
  const options: Intl.NumberFormatOptions = {
    style: 'decimal',
  };

  const locale: AppLanguage = includes(appLanguages, language)
    ? language
    : APP.locales.default;

  return Intl.NumberFormat(locale, options).format(amount);
};
