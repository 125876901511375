import { lazy } from 'react';
import { PageItem } from 'types/Common';
import RecognitionsListPage from '../pages/recognitions-list';
import RecognitionsSettingsPage from '../pages/recognitions-settings';
import RecognitionsAchievementsPage from '../pages/recognitions-achievements';
import RecognitionsAllocationsPage from '../pages/recognitions-allocations';
import RecognitionsGiversPage from '../pages/recognitions-givers';
import RecognitionsRecipientsPage from '../pages/recognitions-recipients';
import RecognitionsLeaderboardPage from '../pages/recognitions-leaderboard';
import RecognitionsRewardsPage from '../pages/recognitions-rewards';
import RecognitionsRulesPage from '../pages/recognitions-rules';
import ShopInternalItemsPage from '../pages/shop-internal-items';
import ShopInternalItemDetailsPage from '../pages/shop-internal-item-details';
import ShopInternalInventoryPage from '../pages/shop-internal-inventory';
import ShopInternalInventoryDetailsPage from '../pages/shop-internal-inventory-details';
import AdminClientAdministratorsPage from '../pages/admin-client-administrators';
import AdminClientSettingsPage from '../pages/admin-client-settings';
import AdminClientFeaturesPage from '../pages/admin-client-features';
import AdminClientDepositsPage from '../pages/admin-client-deposits';
import AdminClientDepositEventsPage from '../pages/admin-client-deposit-events';
import AdminClientDepositHistoryPage from '../pages/admin-client-deposit-history';
import AdminClientCompanyExpensesPage from '../pages/admin-client-company-expenses';
import AdminShopItemSettingsPage from '../pages/admin-shop-item-settings';
import AdminShopItemPricesPage from '../pages/admin-shop-item-prices';
import AdminShopItemContentPage from '../pages/admin-shop-item-content';
import AdminShopItemVouchersPage from '../pages/admin-shop-item-vouchers';
import AdminBrandSettingsPage from '../pages/admin-brand-settings';
import AdminBrandContentPage from '../pages/admin-brand-content';
import AdminBrandCardSettingsPage from '../pages/admin-brand-card-settings';
import AdminBrandCardContentPage from '../pages/admin-brand-card-content';
import AdminBrandCardPriceSettingsPage from '../pages/admin-brand-card-price-settings';
import AdminBrandCardFeesPage from '../pages/admin-brand-card-fees';
// import AdminLogsPage from '../pages/admin-logs';
import {
  GlobalPermissionModules,
  LocalPermissionModules,
  PermissionLevels,
} from '../constants/Administrators';
import { ROUTES } from './routes';

const Home = lazy(() => import('../containers/Home/Home'));
const CompanySettings = lazy(
  () => import('../containers/CompanySettings/CompanySettings'),
);
const AdministratorsInner = lazy(
  () =>
    import(
      '../containers/CompanySettings/views/Administrators/AdministratorsInner/AdministratorsInner'
    ),
);
const EmployeesPage = lazy(() => import('../pages/employees'));
const EmployeesInner = lazy(
  () => import('../containers/Employee/EmployeesInner/EmployeesInner'),
);
const EmployeeGroupsPage = lazy(() => import('../pages/employee-groups'));
const EmployeesGroupDetailsPage = lazy(
  () => import('../pages/employee-group-details'),
);
const NewsDetailsPage = lazy(
  () => import('../containers/news/NewsDetails/NewsDetailsPage'),
);
const ChangePassword = lazy(
  () => import('../containers/ChangePassword/ChangePassword'),
);
const Dashboard = lazy(
  () => import('../containers/Analytics/Dashboard/Dashboard'),
);
const Login = lazy(() => import('../containers/Login/Login'));
const NewPassword = lazy(() => import('../containers/NewPassword/NewPassword'));
const TwoFactor = lazy(() => import('../containers/TwoFactor/TwoFactor'));
const ForgotPassword = lazy(
  () => import('../containers/ForgotPassword/ForgotPassword'),
);
const ResetPassword = lazy(
  () => import('../containers/ResetPassword/ResetPassword'),
);
const ClientsAdministrator = lazy(
  () => import('../containers/ClientsAdministrators/ClientsAdministrators'),
);
const BenefitTemplates = lazy(
  () =>
    import('../containers/BenefitTemplate/BenefitTemplates/BenefitTemplates'),
);
const BenefitTemplate = lazy(
  () => import('../containers/BenefitTemplate/BenefitTemplate/BenefitTemplate'),
);
const SupplierPage = lazy(
  () => import('../containers/Suppliers/Supplier/SupplierPage'),
);
const IntegrationLogsPage = lazy(
  () =>
    import('../containers/Integrations/IntegrationLogs/IntegrationLogsPage'),
);
const MarketplacePage = lazy(
  () => import('../containers/Marketplace/MarketplaceDeals/MarketplacePage'),
);
const AlternativeDetails = lazy(
  () =>
    import(
      '../containers/AlternativesOld/AlternativeDetails/AlternativeDetailsPage'
    ),
);
const SurveysListPage = lazy(
  () => import('../containers/SurveysOld/surveys-list/surveys-list.component'),
);
const SurveyDetailsPage = lazy(
  () =>
    import('../containers/SurveysOld/survey-details/survey-details.component'),
);
const SurveyResultsPage = lazy(
  () =>
    import('../containers/SurveysOld/survey-results/survey-results.component'),
);
const CreateAchievementPage = lazy(
  () =>
    import('../containers/Recognition/CreateAchievement/CreateAchievementPage'),
);
const EditAchievementPage = lazy(
  () => import('../containers/Recognition/EditAchievement/EditAchievementPage'),
);
const FeedbackListPage = lazy(
  () => import('../containers/TrustBox/FeedbackList/FeedbackListPage'),
);
const PageNotFound = lazy(
  () => import('../containers/PageNotFound/PageNotFound'),
);
const BenefitDetailsPage = lazy(
  () => import('../containers/BenefitsOld/BenefitDetails/BenefitDetailsPage'),
);
const EmployeeTurnoverCalculatorPage = lazy(
  () => import('../pages/public/employee-turnover-calculator'),
);
const NewsPage = lazy(() => import('../pages/news'));
const NewsCategoryDetailsPage = lazy(
  () => import('../pages/news-category-details'),
);
const AdminClientsPage = lazy(() => import('../pages/admin-clients'));
const AdminAdminsPage = lazy(() => import('../pages/admin-admins'));
const AdminAdminDetailsPage = lazy(
  () => import('../pages/admin-admin-details'),
);
const AlternativesPage = lazy(() => import('../pages/alternatives'));
const ExpensesPage = lazy(() => import('../pages/expenses'));
const ExternalExpensesPage = lazy(() => import('../pages/external-expenses'));
const AdminDepositBalancesPerClientPage = lazy(
  () => import('../pages/admin-deposit-balances-per-client'),
);
const AdminDepositBalancesPerCompanyPage = lazy(
  () => import('../pages/admin-deposit-balances-per-company'),
);
const AdminReportsPage = lazy(() => import('../pages/admin-reports'));
const AdminOrdersPage = lazy(() => import('../pages/admin-orders'));
const AdminOrderPage = lazy(() => import('../pages/admin-order'));
const AdminDiscountsPage = lazy(() => import('../pages/admin-discounts'));
const AdminDiscountDetailsPage = lazy(
  () => import('../pages/admin-discount-details'),
);
const AdminDiscountCodesPage = lazy(
  () => import('../pages/admin-discount-codes'),
);
const AdminIntegrationsPage = lazy(() => import('../pages/admin-integrations'));
const AdminIntegrationDetailsPage = lazy(
  () => import('../pages/admin-integration-details'),
);
const AdminSuppliersPage = lazy(() => import('../pages/admin-suppliers'));
const AdminShopItems = lazy(() => import('../pages/admin-shop-items'));
const AdminBrandsPage = lazy(() => import('../pages/admin-brands'));
const AdminBrandCardsPage = lazy(() => import('../pages/admin-brand-cards'));
const OrdersPage = lazy(() => import('../pages/orders'));
const OrderInternalPage = lazy(() => import('../pages/order-internal'));
const ClaimsPage = lazy(() => import('../pages/claims'));
const ClaimDetailsPage = lazy(() => import('../pages/claim-details'));
const WalletsPage = lazy(() => import('../pages/wallets'));
const LogsPage = lazy(() => import('../pages/logs'));
const BenefitsPage = lazy(() => import('../pages/benefits'));
const DiscountsPage = lazy(() => import('../pages/discounts'));
const AutomationsPage = lazy(() => import('../pages/automations'));
const AutomationDetailsPage = lazy(() => import('../pages/automation-details'));

export const pages = [
  {
    path: ROUTES.root.index,
    component: Home,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.public.employeeTurnoverCalculator,
    component: EmployeeTurnoverCalculatorPage,
    public: true,
  },
  {
    path: ROUTES.benefits.list,
    component: BenefitsPage,
    roles: ['hrAdmin'],
    module: GlobalPermissionModules.Benefits,
    permissionLevel: PermissionLevels.Global,
  },
  {
    path: ROUTES.benefits.details,
    component: BenefitDetailsPage,
    roles: ['hrAdmin'],
    module: GlobalPermissionModules.Benefits,
    permissionLevel: PermissionLevels.Global,
  },
  {
    path: '/more/company-settings',
    component: CompanySettings,
    roles: ['hrAdmin'],
    module: [
      GlobalPermissionModules.Administrators,
      GlobalPermissionModules.OrganisationStructure,
    ],
    permissionLevel: PermissionLevels.Global,
  },
  {
    path: '/more/company-settings/integrations/:id/logs',
    component: IntegrationLogsPage,
    roles: ['hrAdmin'],
    module: [
      GlobalPermissionModules.Administrators,
      GlobalPermissionModules.OrganisationStructure,
    ],
    permissionLevel: PermissionLevels.Global,
  },
  {
    path: ROUTES.administrators.details,
    component: AdministratorsInner,
    roles: ['hrAdmin'],
    module: GlobalPermissionModules.Administrators,
    permissionLevel: PermissionLevels.Global,
  },
  {
    path: ROUTES.employees.list,
    component: EmployeesPage,
    roles: ['hrAdmin'],
    module: LocalPermissionModules.Employees,
    permissionLevel: PermissionLevels.Local,
  },
  {
    path: ROUTES.employees.details,
    component: EmployeesInner,
    roles: ['hrAdmin'],
    module: LocalPermissionModules.Employees,
    permissionLevel: PermissionLevels.Local,
  },
  {
    path: ROUTES.auth.changePassword,
    component: ChangePassword,
    roles: ['hrAdmin', 'melpAdmin'],
  },
  {
    path: ROUTES.news.list,
    component: NewsPage,
    roles: ['hrAdmin'],
    module: LocalPermissionModules.News,
    permissionLevel: PermissionLevels.Local,
  },
  {
    path: ROUTES.news.categories.create,
    component: NewsCategoryDetailsPage,
    roles: ['hrAdmin'],
    module: LocalPermissionModules.News,
    permissionLevel: PermissionLevels.Local,
  },
  {
    path: ROUTES.news.categories.details,
    component: NewsCategoryDetailsPage,
    roles: ['hrAdmin'],
    module: LocalPermissionModules.News,
    permissionLevel: PermissionLevels.Local,
  },
  {
    path: ROUTES.analytics,
    component: Dashboard,
    roles: ['hrAdmin'],
    module: LocalPermissionModules.Dashboard,
    permissionLevel: PermissionLevels.Local,
  },
  {
    path: ROUTES.logs.list,
    component: LogsPage,
    roles: ['hrAdmin'],
    module: LocalPermissionModules.History,
    permissionLevel: PermissionLevels.Local,
  },
  {
    path: ROUTES.auth.login,
    component: Login,
    public: true,
  },
  {
    path: ROUTES.auth.newPassword,
    component: NewPassword,
    public: true,
  },
  {
    path: ROUTES.employeeGroups.list,
    component: EmployeeGroupsPage,
    roles: ['hrAdmin'],
    module: GlobalPermissionModules.EmployeeGroups,
    permissionLevel: PermissionLevels.Global,
  },
  {
    path: ROUTES.employeeGroups.details,
    component: EmployeesGroupDetailsPage,
    roles: ['hrAdmin'],
    module: [
      GlobalPermissionModules.Benefits,
      LocalPermissionModules.Employees,
    ],
    permissionLevel: PermissionLevels.Global,
  },
  {
    path: ROUTES.news.details,
    component: NewsDetailsPage,
    roles: ['hrAdmin'],
    module: LocalPermissionModules.News,
    permissionLevel: PermissionLevels.Local,
  },
  {
    path: ROUTES.auth.twoFactor,
    component: TwoFactor,
    public: true,
  },
  {
    path: ROUTES.auth.forgotPassword,
    component: ForgotPassword,
    public: true,
  },
  {
    path: ROUTES.auth.resetPassword,
    component: ResetPassword,
    public: true,
  },
  {
    path: ROUTES.admin.deposits.balances.clients.list,
    component: AdminDepositBalancesPerClientPage,
    roles: ['melpAdmin'],
    adminModules: ['clientCompanies'],
  },
  {
    path: ROUTES.admin.deposits.balances.companies.list,
    component: AdminDepositBalancesPerCompanyPage,
    roles: ['melpAdmin'],
    adminModules: ['clientCompanies'],
  },
  {
    path: ROUTES.admin.admins.list,
    component: AdminAdminsPage,
    roles: ['melpAdmin'],
    adminModules: ['melpAdmin'],
  },
  {
    path: ROUTES.admin.admins.create,
    component: AdminAdminDetailsPage,
    roles: ['melpAdmin'],
    adminModules: ['melpAdmin'],
  },
  {
    path: ROUTES.admin.admins.details,
    component: AdminAdminDetailsPage,
    roles: ['melpAdmin'],
    adminModules: ['melpAdmin'],
  },
  {
    path: ROUTES.admin.clients.list,
    component: AdminClientsPage,
    roles: ['melpAdmin'],
    adminModules: ['clientCompanies'],
  },
  {
    path: ROUTES.admin.clients.details.settings,
    component: AdminClientSettingsPage,
    roles: ['melpAdmin'],
    adminModules: ['clientCompanies'],
  },
  {
    path: ROUTES.admin.clients.details.features,
    component: AdminClientFeaturesPage,
    roles: ['melpAdmin'],
    adminModules: ['clientCompanies'],
  },
  {
    path: ROUTES.admin.clients.details.deposits.list,
    component: AdminClientDepositsPage,
    roles: ['melpAdmin'],
    adminModules: ['clientCompanies'],
  },
  {
    path: ROUTES.admin.clients.details.deposits.history,
    component: AdminClientDepositHistoryPage,
    roles: ['melpAdmin'],
    adminModules: ['clientCompanies'],
  },
  {
    path: ROUTES.admin.clients.details.depositEvents.list,
    component: AdminClientDepositEventsPage,
    roles: ['melpAdmin'],
    adminModules: ['clientCompanies'],
  },
  {
    path: ROUTES.admin.clients.details.administrators.list,
    component: AdminClientAdministratorsPage,
    roles: ['melpAdmin'],
    adminModules: ['clientCompanies'],
  },
  {
    path: ROUTES.admin.clients.details.expenses.details,
    component: AdminClientCompanyExpensesPage,
    roles: ['melpAdmin'],
    adminModules: ['clientCompanies'],
  },
  {
    path: ROUTES.admin.clients.details.administrators.details,
    component: ClientsAdministrator,
    roles: ['melpAdmin'],
    adminModules: ['clientCompanies'],
  },
  {
    path: ROUTES.admin.integrations.list,
    component: AdminIntegrationsPage,
    roles: ['melpAdmin'],
    adminModules: ['integrations'],
  },
  {
    path: ROUTES.admin.integrations.details,
    component: AdminIntegrationDetailsPage,
    roles: ['melpAdmin'],
    adminModules: ['integrations'],
  },
  {
    path: ROUTES.admin.templates.list,
    component: BenefitTemplates,
    roles: ['melpAdmin'],
    adminModules: ['templates'],
  },
  {
    path: ROUTES.admin.templates.details,
    component: BenefitTemplate,
    roles: ['melpAdmin'],
    adminModules: ['templates'],
  },
  {
    path: ROUTES.admin.suppliers.list,
    component: AdminSuppliersPage,
    roles: ['melpAdmin'],
    adminModules: ['suppliers'],
  },
  {
    path: ROUTES.admin.suppliers.details,
    component: SupplierPage,
    roles: ['melpAdmin'],
    adminModules: ['suppliers'],
  },
  {
    path: ROUTES.admin.shopItems.list,
    component: AdminShopItems,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.shopItems.details.settings,
    component: AdminShopItemSettingsPage,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.shopItems.details.prices,
    component: AdminShopItemPricesPage,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.shopItems.details.content,
    component: AdminShopItemContentPage,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.shopItems.details.vouchers,
    component: AdminShopItemVouchersPage,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.brands.list,
    component: AdminBrandsPage,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.brands.settings,
    component: AdminBrandSettingsPage,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.brands.content,
    component: AdminBrandContentPage,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.cards.list,
    component: AdminBrandCardsPage,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.cards.settings,
    component: AdminBrandCardSettingsPage,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.cards.content,
    component: AdminBrandCardContentPage,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.cards.price,
    component: AdminBrandCardPriceSettingsPage,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.cards.fees,
    component: AdminBrandCardFeesPage,
    roles: ['melpAdmin'],
    adminModules: ['shopItems'],
  },
  {
    path: ROUTES.admin.reports,
    component: AdminReportsPage,
    roles: ['melpAdmin'],
    adminModules: ['reports'],
  },
  // TODO: uncomment when BE fixes admin logs
  // {
  //   path: ROUTES.admin.logs,
  //   component: AdminLogsPage,
  //   roles: ['melpAdmin'],
  // },
  {
    path: ROUTES.admin.orders.list,
    component: AdminOrdersPage,
    roles: ['melpAdmin'],
    adminModules: ['orders'],
  },
  {
    path: ROUTES.admin.orders.details,
    component: AdminOrderPage,
    roles: ['melpAdmin'],
    adminModules: ['orders'],
  },
  {
    path: ROUTES.admin.discounts.list,
    component: AdminDiscountsPage,
    roles: ['melpAdmin'],
    adminModules: ['discounts'],
  },
  {
    path: ROUTES.admin.discounts.create,
    component: AdminDiscountDetailsPage,
    roles: ['melpAdmin'],
    adminModules: ['discounts'],
  },
  {
    path: ROUTES.admin.discounts.details.settings,
    component: AdminDiscountDetailsPage,
    roles: ['melpAdmin'],
    adminModules: ['discounts'],
  },
  {
    path: ROUTES.admin.discounts.details.codes,
    component: AdminDiscountCodesPage,
    roles: ['melpAdmin'],
    adminModules: ['discounts'],
  },
  {
    path: ROUTES.shop.external.list,
    component: MarketplacePage,
    roles: ['hrAdmin'],
    module: GlobalPermissionModules.Benefits,
    permissionLevel: PermissionLevels.Global,
  },
  {
    path: ROUTES.shop.internal.items.list,
    component: ShopInternalItemsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.shop.internal.items.create,
    component: ShopInternalItemDetailsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.shop.internal.items.details,
    component: ShopInternalItemDetailsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.shop.internal.inventory.list,
    component: ShopInternalInventoryPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.shop.internal.inventory.details,
    component: ShopInternalInventoryDetailsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.discounts.list,
    component: DiscountsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.orders.list,
    component: OrdersPage,
    roles: ['hrAdmin'],
    module: GlobalPermissionModules.Benefits,
    permissionLevel: PermissionLevels.Global,
  },
  {
    path: ROUTES.orders.internal.details,
    component: OrderInternalPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.alternatives.list,
    component: AlternativesPage,
    roles: ['hrAdmin'],
    module: GlobalPermissionModules.BenefitAlternatives,
    permissionLevel: PermissionLevels.Global,
  },
  {
    path: ROUTES.alternatives.details,
    component: AlternativeDetails,
    roles: ['hrAdmin'],
    module: GlobalPermissionModules.BenefitAlternatives,
    permissionLevel: PermissionLevels.Global,
  },
  {
    path: ROUTES.surveys.list,
    component: SurveysListPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.surveys.details,
    component: SurveyDetailsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.surveys.results,
    component: SurveyResultsPage,
    roles: ['hrAdmin'],
  },

  {
    path: ROUTES.recognitions.list,
    component: RecognitionsListPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.recognitions.settings,
    component: RecognitionsSettingsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.recognitions.achievements.list,
    component: RecognitionsAchievementsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.recognitions.achievements.create,
    component: CreateAchievementPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.recognitions.achievements.details,
    component: EditAchievementPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.recognitions.allocations.list,
    component: RecognitionsAllocationsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.recognitions.givers.list,
    component: RecognitionsGiversPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.recognitions.recipients.list,
    component: RecognitionsRecipientsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.recognitions.leaderboard.list,
    component: RecognitionsLeaderboardPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.recognitions.rewards.list,
    component: RecognitionsRewardsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.recognitions.rules.list,
    component: RecognitionsRulesPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.trustBox.list,
    component: FeedbackListPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.automations.list,
    component: AutomationsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.automations.create,
    component: AutomationDetailsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.automations.details,
    component: AutomationDetailsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.wallets.list,
    component: WalletsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.expenses.list,
    component: ExpensesPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.expenses.external.list,
    component: ExternalExpensesPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.claims.list,
    component: ClaimsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.claims.details,
    component: ClaimDetailsPage,
    roles: ['hrAdmin'],
  },
  {
    path: ROUTES.root.notFound,
    component: PageNotFound,
    public: true,
  },
] satisfies PageItem[];
