import { APP } from 'config';
import { AppLanguage, appLanguages } from 'types/general';
import { includes } from 'utils/general';

export const formatPercents = (language: string, percent: number): string => {
  const options: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };

  const locale: AppLanguage = includes(appLanguages, language)
    ? language
    : APP.locales.default;

  return new Intl.NumberFormat(locale, options).format(percent || 0);
};
