import { APP } from 'config';
import { AppLanguage, appLanguages } from 'types/general';
import { includes } from 'utils/general';

export const formatList = (
  language: string,
  list: string[],
  options: Intl.ListFormatOptions = {},
): string => {
  const locale: AppLanguage = includes(appLanguages, language)
    ? language
    : APP.locales.default;

  return new Intl.ListFormat(locale, { type: 'unit', ...options }).format(list);
};
