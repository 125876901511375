import { useTranslation } from 'react-i18next';
import { ROUTES } from 'config';
import { useMe } from 'state/Administrators';
import { LinkItem } from 'types/Common';
import { sum } from 'utils/general';
import { useAdminDepositPendingActions } from 'store/admin-client-deposits';
import {
  GlobalPermissionModules,
  LocalPermissionModules,
} from '../constants/Administrators';

export const useLinks = (): LinkItem[] => {
  const { t } = useTranslation();
  const { isFeatureEnabled, isMelpAdmin } = useMe();

  const { data: pendingActions } = useAdminDepositPendingActions({
    enabled: isMelpAdmin,
  });

  return [
    {
      title: t('menu.analytics'),
      to: ROUTES.analytics,
      roles: ['hrAdmin'],
      module: LocalPermissionModules.Dashboard,
    },
    {
      title: t('menu.benefits'),
      roles: ['hrAdmin'],
      module: GlobalPermissionModules.Benefits,
      links: [
        {
          title: t('menu.all_benefits'),
          to: ROUTES.benefits.list,
          roles: ['hrAdmin'],
          module: GlobalPermissionModules.Benefits,
        },
        {
          title: t('alternatives.alternatives'),
          to: ROUTES.alternatives.list,
          roles: ['hrAdmin'],
          module: GlobalPermissionModules.BenefitAlternatives,
        },
        ...(isFeatureEnabled('discounts')
          ? [
              {
                title: t('discounts.title'),
                to: ROUTES.discounts.list,
                roles: ['hrAdmin' as const],
              },
            ]
          : []),
      ],
    },
    {
      title: t('menu.marketplace'),
      roles: ['hrAdmin'],
      module: GlobalPermissionModules.Benefits,
      links: [
        {
          title: t('menu.marketplaceDeals'),
          to: ROUTES.shop.external.list,
          roles: ['hrAdmin'],
        },
        {
          title: t('shop.internal.title'),
          to: ROUTES.shop.internal.items.list,
          roles: ['hrAdmin'],
        },
        {
          title: t('menu.marketplaceOrders'),
          to: ROUTES.orders.list,
          roles: ['hrAdmin'],
        },
        {
          title: t('wallet.wallet'),
          to: ROUTES.wallets.list,
          roles: ['hrAdmin'],
        },
        {
          title: t('expenses.title'),
          to: ROUTES.expenses.list,
          roles: ['hrAdmin'],
        },
        {
          title: t('expenses.filters.type.options.manual'),
          to: ROUTES.expenses.external.list,
          roles: ['hrAdmin'],
        },
        {
          title: t('claims.title'),
          to: ROUTES.claims.list,
          roles: ['hrAdmin'],
        },
      ],
    },
    {
      title: t('exportFileName.employees'),
      to: ROUTES.employees.list,
      roles: ['hrAdmin'],
      module: LocalPermissionModules.Employees,
    },
    {
      title: t('menu.groups'),
      to: ROUTES.employeeGroups.list,
      roles: ['hrAdmin'],
      module: GlobalPermissionModules.EmployeeGroups,
    },
    {
      title: t('menu.communication'),
      roles: ['hrAdmin'],
      links: [
        {
          title: t('permissions.newsModule'),
          to: ROUTES.news.list,
          roles: ['hrAdmin'],
          module: LocalPermissionModules.News,
        },
        {
          title: t('surveys.surveys'),
          to: ROUTES.surveys.list,
          roles: ['hrAdmin'],
        },
        {
          title: t('recognition.recognition'),
          to: ROUTES.recognitions.list,
          roles: ['hrAdmin'],
        },
        {
          title: t('trustBox.trustBox'),
          to: ROUTES.trustBox.list,
          roles: ['hrAdmin'],
        },
      ],
    },
    {
      title: t('sidebar.more'),
      roles: ['hrAdmin'],
      module: [
        GlobalPermissionModules.Administrators,
        GlobalPermissionModules.OrganisationStructure,
        LocalPermissionModules.Employees,
      ],
      links: [
        {
          title: t('automation.automation'),
          to: ROUTES.automations.list,
          roles: ['hrAdmin'],
          module: [],
          matchAllModules: true,
        },
        {
          title: t('sidebar.log'),
          to: ROUTES.logs.list,
          roles: ['hrAdmin'],
          module: LocalPermissionModules.History,
        },
        {
          title: t('sidebar.companySettings'),
          to: '/more/company-settings',
          roles: ['hrAdmin'],
          module: [
            GlobalPermissionModules.Administrators,
            GlobalPermissionModules.OrganisationStructure,
          ],
        },
      ],
    },
    {
      title: t('menu.clients'),
      roles: ['melpAdmin'],
      badge: pendingActions
        ? sum(pendingActions.clients, pendingActions.companies)
        : undefined,
      links: [
        {
          title: t('clients.clients.title'),
          to: ROUTES.admin.clients.list,
          roles: ['melpAdmin'],
        },
        {
          title: t('deposits.deposit_balance.title'),
          to: ROUTES.admin.deposits.balances.clients.list,
          roles: ['melpAdmin'],
          badge: pendingActions
            ? sum(pendingActions.clients, pendingActions.companies)
            : undefined,
        },
      ],
    },
    {
      title: t('menu.templates'),
      to: ROUTES.admin.templates.list,
      roles: ['melpAdmin'],
    },
    {
      title: t('menu.integrations'),
      to: ROUTES.admin.integrations.list,
      roles: ['melpAdmin'],
    },
    {
      title: t('menu.marketplace'),
      to: ROUTES.admin.suppliers.list,
      roles: ['melpAdmin'],
      links: [
        {
          title: t('menu.suppliers'),
          to: ROUTES.admin.suppliers.list,
          roles: ['melpAdmin'],
        },
        {
          title: t('menu.items'),
          to: ROUTES.admin.shopItems.list,
          roles: ['melpAdmin'],
        },
        {
          title: t('menu.orders'),
          to: ROUTES.admin.orders.list,
          roles: ['melpAdmin'],
        },
        {
          title: t('brands.title'),
          to: ROUTES.admin.brands.list,
          roles: ['melpAdmin'],
        },
        {
          title: t('cards.title'),
          to: ROUTES.admin.cards.list,
          roles: ['melpAdmin'],
        },
      ],
    },
    {
      title: t('discounts.title'),
      to: ROUTES.admin.discounts.list,
      roles: ['melpAdmin'],
    },
    {
      title: t('alternatives.reports.title'),
      to: ROUTES.admin.reports,
      roles: ['melpAdmin'],
    },
    {
      title: t('sidebar.administrators'),
      to: ROUTES.admin.admins.list,
      roles: ['melpAdmin'],
    },
    // TODO: uncomment when BE fixes admin logs
    // {
    //   title: t('sidebar.log'),
    //   to: ROUTES.admin.logs,
    //   roles: ['melpAdmin'],
    // },
  ] as const;
};
