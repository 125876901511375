import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './recognitions.loaders';
import { recognitionsKeys } from './recognitions.queries';

export const useExportToXls = () => {
  return useMutation(loaders.exportXls, {
    onSuccess: filesConverters.export,
  });
};

export const useDownloadTemplate = () => {
  return useMutation(loaders.downloadTemplate, {
    onSuccess: filesConverters.export,
  });
};

export const useApproveRecognition = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.approveRecognition, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionsKeys.all);
    },
  });
};

export const useEditRecognition = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.editRecognition, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionsKeys.all);
    },
  });
};

export const useDeleteRecognition = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteRecognition, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionsKeys.all);
    },
  });
};

export const useImportRecognitions = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.importRecognitions, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionsKeys.lists);
    },
  });
};

export const useUpdateRecognitionsSettings = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateRecognitionsSettings, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionsKeys.detail('settings'));
    },
  });
};

export const useTriggerRecognitionsExpiration = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.triggerExpiration, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionsKeys.lists);
    },
  });
};
