import {
  AutocompleteInput,
  FileInput,
  ImageList,
  Modal,
  NumberInput,
  SelectInput,
  TextField,
} from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { Delete } from '@mui/icons-material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { ShopItemCategory, shopItemCategories } from 'types/MarketplaceItems';
import { usePredefinedToasts } from 'utils/Toast';
import { Stack } from '@mui/material';
import { BenefitCategory, benefitCategories } from 'store/benefits';
import { ShopItemStatus } from 'store/shop';
import { Currency, currencies } from 'types/general';
import { useCreateShopItem } from 'store/admin-shop';
import { useAdminSuppliers } from 'store/admin-suppliers';
import { APP, ROUTES } from 'config';
import { formatCountry } from 'utils/format';
import { useUploadFile } from 'store/files';
import { routerHistory } from 'utils/router-history';

interface Values {
  name: string;
  supplierId: string;
  status: ShopItemStatus;
  price: number;
  currency: Currency;
  vatRate: number;
  countries: string[];
  cities: string[];
  categories: ShopItemCategory[];
  benefitCategories: BenefitCategory[];
  imageId?: string;
  priority: number;
  searchTags: string;
}

const INITIAL_VALUES: Values = {
  name: '',
  supplierId: '',
  status: 'inactive',
  price: NaN,
  currency: 'EUR',
  vatRate: NaN,
  countries: [],
  cities: [],
  categories: [],
  benefitCategories: [],
  imageId: '',
  priority: 0,
  searchTags: '',
};

interface Props extends ModalProps {}

export const CreateShopItemModal: FC<Props> = ({ closeModal }) => {
  const { t, i18n } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const { control, handleSubmit, watch } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });

  const { data: supplierOptions, isLoading: isSuppliersLoading } =
    useAdminSuppliers({
      pageSize: 99999,
    });

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile();
  const { mutate: createShopItem, isLoading: isCreating } = useCreateShopItem();

  const onSubmit: SubmitHandler<Values> = (values) => {
    createShopItem(
      {
        supplierId: values.supplierId,
        item: {
          name: values.name,
          status: 'inactive',
          price: 1,
          currency: values.currency,
          vatRate: values.vatRate || 0,
          countries: values.countries,
          cities: values.cities,
          categories: values.categories,
          benefitCategories: values.benefitCategories,
          imageId: values.imageId,
          priority: values.priority,
          searchTags: values.searchTags,
        },
      },
      {
        onSuccess: ({ data }) => {
          predefinedToasts.success.added();
          closeModal({ action: 'CLOSE' });
          routerHistory.push(
            ROUTES.admin.shopItems.details.settings.replace(':id', data.id),
          );
        },
      },
    );
  };

  const [imageId] = watch(['imageId']);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('marketplaceItems.addMarketplaceItem')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.confirm'),
            type: 'submit',
            disabled: isCreating,
          },
        ]}
      >
        <Stack gap="20px">
          <Controller
            name="supplierId"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <AutocompleteInput
                  label={t('marketplaceItems.supplier')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  options={
                    supplierOptions?.items.map((supplier) => ({
                      value: supplier.id,
                      label: supplier.name,
                    })) ?? []
                  }
                  disabled={isSuppliersLoading}
                  required
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="name"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <TextField
                label={t('common.systemName')}
                name={name}
                value={value}
                onChange={onChange}
                required
                ref={ref}
              />
            )}
          />

          <Controller
            name="currency"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <SelectInput
                  label={t('marketplaceItems.currency')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  options={currencies.map((currency) => ({
                    label: currency,
                    value: currency,
                  }))}
                  required
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="vatRate"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <NumberInput
                label={t('marketplaceItems.vatRate')}
                name={name}
                value={value}
                onChange={onChange}
                key={value}
                ref={ref}
              />
            )}
          />

          <Controller
            name="countries"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <AutocompleteInput
                label={t('marketplaceItems.countries')}
                name={name}
                value={value}
                onChange={onChange}
                options={APP.countries.alpha3.map((alpha3) => ({
                  value: alpha3,
                  label: formatCountry(i18n.language, alpha3),
                }))}
                required
                multiple
                ref={ref}
              />
            )}
          />

          <Controller
            name="cities"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <AutocompleteInput
                label={t('common.city.label')}
                name={name}
                value={value}
                onChange={onChange}
                options={APP.cities.map((city) => ({
                  value: city,
                  label: city,
                }))}
                multiple
                ref={ref}
              />
            )}
          />

          <Controller
            name="benefitCategories"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <AutocompleteInput
                label={t('marketplaceItems.benefitPlanTypes')}
                name={name}
                value={value}
                onChange={onChange}
                options={benefitCategories.map((category) => ({
                  value: category,
                  label: t(`menu.${category}`),
                }))}
                multiple
                required
                ref={ref}
              />
            )}
          />

          <Controller
            name="categories"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <AutocompleteInput
                label={t('marketplaceItems.categories')}
                name={name}
                value={value}
                onChange={onChange}
                options={shopItemCategories.map((category) => ({
                  label: t(`marketplaceItems.itemCategory-${category}`),
                  value: category,
                }))}
                multiple
                required
                ref={ref}
              />
            )}
          />

          <Controller
            name="priority"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <NumberInput
                label={t('common.priority.label')}
                name={name}
                value={value}
                onChange={onChange}
                required
                type="integer"
                key={value}
                ref={ref}
              />
            )}
          />

          <Controller
            name="searchTags"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <TextField
                label={t('shop.search_tags.label')}
                name={name}
                value={value}
                onChange={onChange}
                rows={5}
                ref={ref}
              />
            )}
          />

          <Controller
            name="imageId"
            control={control}
            render={({ field: { name, onChange, ref } }) => {
              return imageId ? (
                <ImageList
                  items={[
                    {
                      imageId,
                      actions: [
                        {
                          icon: Delete,
                          variant: 'neutral-outline',
                          onClick: () => onChange(''),
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                <FileInput
                  name={name}
                  onChange={([file]) => {
                    if (file) {
                      uploadFile(
                        { file },
                        {
                          onSuccess: ({ data }) => {
                            onChange(data.id);
                          },
                        },
                      );
                    } else {
                      onChange('');
                    }
                  }}
                  disabled={isUploading}
                  maxFiles={1}
                  previewSelectedFiles={false}
                  accept={['jpg', 'jpeg', 'png', 'gif', 'webp']}
                  ref={ref}
                />
              );
            }}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
