import { MpAsyncGetMethod } from '@mp-react/table';
import { AxiosResponse } from 'axios';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSWR, { mutate } from 'swr';
import { useCurrentUser } from 'store/auth';
import { ROUTES } from 'config';
import { ClientFeature } from 'store/admin-clients';
import { AdminPermission } from 'store/admin-admins';
import { makeRequest } from '../api/api';
import { Endpoints } from '../api/constants';
import {
  AdministratorFilterNames,
  GlobalPermissionModules,
  LocalPermissionModules,
  PermissionLevels,
} from '../constants/Administrators';
import {
  Administrator,
  AdministratorListItem,
  Permission,
  UpdateMeRequest,
  UseAdministrators,
  UseMe,
} from '../types/Administrators';
import { useAdminUtils } from '../utils/Administrators';
import { useCustomSwrMutation } from '../utils/Api';
import { useLoading } from '../utils/Loading';

const initalMeValues: UseMe = {
  loading: false,
  globalPermissions: [],
  localPermissions: [],
  adminPermissions: [],
  getPermissionsByModule: () => [],
  checkIfCanEdit: () => false,
  checkIfCanView: () => false,
  canEditGroups: false,
  canEditAdministrators: false,
  canEditGeneralSettings: false,
  canEditCustomEmployeeCategory: false,
  canEditCompanies: false,
  canEditCompanyGroups: false,
  canViewDashboard: false,
  canEditEmployees: false,
  canEditBenefits: false,
  canEditNews: false,
  hasBenefitPermissions: false,
  hasEmployeePermissions: false,
  hasDashboardPermissions: false,
  hasAdministratorPermissions: false,
  hasGroupPermissions: false,
  hasNewsPermissions: false,
  hasOrganisationsPermissions: false,
  isRoot: false,
  hasNoCompanySettingsAccess: false,
  isMelpAdmin: false,
  isHrAdmin: false,
  updateMe: () => {
    throw new Error('Administrator context is not initialized');
  },
  isFeatureEnabled: () => false,
};

const meContext = createContext<UseMe>(initalMeValues);

const useProvideMe = (): UseMe => {
  const { data: me, isLoading: apiLoading } = useCurrentUser();

  const isRoot = useMemo(() => me?.root, [me?.root]);
  const isMelpAdmin = useMemo(() => me?.role === 'melpAdmin', [me?.role]);
  const isHrAdmin = useMemo(() => me?.role === 'hrAdmin', [me?.role]);

  const globalPermissions = useMemo<Permission[]>(() => {
    if (!me?.permissions) return [];
    return me?.permissions.filter(
      (permission) => permission.level === PermissionLevels.Global,
    );
  }, [me?.permissions]);

  const localPermissions = useMemo<Permission[]>(() => {
    if (!me?.permissions) return [];
    return me?.permissions.filter(
      (permission) => permission.level === PermissionLevels.Local,
    );
  }, [me?.permissions]);

  const adminPermissions = useMemo<AdminPermission[]>(() => {
    return me?.adminPermissions ?? [];
  }, [me?.adminPermissions]);

  const getPermissionsByModule = useCallback(
    (
      module:
        | LocalPermissionModules
        | GlobalPermissionModules
        | Array<LocalPermissionModules | GlobalPermissionModules>,
    ): Permission[] => {
      const modules = Array.isArray(module) ? module : [module];
      if (!me?.permissions) return [];
      return me?.permissions.filter((permission) =>
        modules?.includes(permission.module),
      );
    },
    [me?.permissions],
  );

  const checkIfCanEdit = useCallback(
    (module: LocalPermissionModules | GlobalPermissionModules) => {
      if (isRoot) return true;
      const permissions = getPermissionsByModule(module);
      if (permissions.length === 0) return false;
      return permissions.some((permission) => permission.permission === 'edit');
    },
    [getPermissionsByModule, isRoot],
  );

  const checkIfCanView = useCallback(
    (module: LocalPermissionModules | GlobalPermissionModules) => {
      if (isRoot) return true;
      const permissions = getPermissionsByModule(module);
      if (permissions.length === 0) return false;
      return permissions.some((permission) => permission.permission === 'view');
    },
    [getPermissionsByModule, isRoot],
  );

  const checkIfHasNoAccess = useCallback(
    (module: LocalPermissionModules | GlobalPermissionModules) => {
      if (isRoot) return false;
      const permissions = getPermissionsByModule(module);
      if (permissions.length === 0) return true;
      return permissions.some((permission) => permission.permission === 'none');
    },
    [getPermissionsByModule, isRoot],
  );

  const hasNoAdminAccess = useMemo(
    () => checkIfHasNoAccess(GlobalPermissionModules.Administrators),
    [checkIfHasNoAccess],
  );

  const hasNoOrganisationsAccess = useMemo(
    () => checkIfHasNoAccess(GlobalPermissionModules.OrganisationStructure),
    [checkIfHasNoAccess],
  );

  const hasNoCompanySettingsAccess = useMemo(
    () => hasNoOrganisationsAccess && hasNoAdminAccess,
    [hasNoOrganisationsAccess, hasNoAdminAccess],
  );

  const canEditNews = useMemo(
    () => checkIfCanEdit(LocalPermissionModules.News),
    [checkIfCanEdit],
  );

  const canViewNews = useMemo(
    () => checkIfCanView(LocalPermissionModules.News),
    [checkIfCanView],
  );

  const hasNewsPermissions = useMemo(
    () => canEditNews || canViewNews,
    [canEditNews, canViewNews],
  );

  const canEditBenefits = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.Benefits),
    [checkIfCanEdit],
  );

  const canViewBenefits = useMemo(
    () => checkIfCanView(GlobalPermissionModules.Benefits),
    [checkIfCanView],
  );

  const hasBenefitPermissions = useMemo(
    () => canEditBenefits || canViewBenefits,
    [canEditBenefits, canViewBenefits],
  );

  const canEditEmployees = useMemo(
    () => checkIfCanEdit(LocalPermissionModules.Employees),
    [checkIfCanEdit],
  );

  const canViewEmployees = useMemo(
    () => checkIfCanView(LocalPermissionModules.Employees),
    [checkIfCanView],
  );

  const hasEmployeePermissions = useMemo(
    () => canEditEmployees || canViewEmployees,
    [canEditEmployees, canViewEmployees],
  );

  const canEditDashboard = useMemo(
    () => checkIfCanEdit(LocalPermissionModules.Dashboard),
    [checkIfCanEdit],
  );

  const canViewDashboard = useMemo(
    () => checkIfCanView(LocalPermissionModules.Dashboard),
    [checkIfCanView],
  );

  const hasDashboardPermissions = useMemo(
    () => canEditDashboard || canViewDashboard,
    [canEditDashboard, canViewDashboard],
  );

  const canEditAdministrators = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.Administrators),
    [checkIfCanEdit],
  );

  const canEditGeneralSettings = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.OrganisationStructure),
    [checkIfCanEdit],
  );

  const canEditCustomEmployeeCategory = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.OrganisationStructure),
    [checkIfCanEdit],
  );

  const canEditCompanies = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.OrganisationStructure),
    [checkIfCanEdit],
  );

  const canEditCompanyGroups = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.OrganisationStructure),
    [checkIfCanEdit],
  );

  const canViewCompanySettings = useMemo(
    () => checkIfCanView(GlobalPermissionModules.OrganisationStructure),
    [checkIfCanView],
  );

  const hasOrganisationsPermissions = useMemo(
    () => canViewCompanySettings || canEditCustomEmployeeCategory,
    [canEditCustomEmployeeCategory, canViewCompanySettings],
  );

  const canViewAdministrators = useMemo(
    () => checkIfCanView(GlobalPermissionModules.Administrators),
    [checkIfCanView],
  );

  const hasAdministratorPermissions = useMemo(
    () => canEditAdministrators || canViewAdministrators,
    [canEditAdministrators, canViewAdministrators],
  );

  const canEditGroups = useMemo(
    () => checkIfCanEdit(GlobalPermissionModules.EmployeeGroups),
    [checkIfCanEdit],
  );

  const canViewGroups = useMemo(
    () => checkIfCanView(GlobalPermissionModules.EmployeeGroups),
    [checkIfCanView],
  );

  const hasGroupPermissions = useMemo(
    () => canEditGroups || canViewGroups,
    [canEditGroups, canViewGroups],
  );

  const updateMe = useCustomSwrMutation<UpdateMeRequest>(Endpoints.me, 'patch');

  const isFeatureEnabled = (featureKey: ClientFeature) =>
    me?.parentCompany.features.includes(featureKey) ?? false;

  return {
    me,
    loading: apiLoading,
    globalPermissions,
    localPermissions,
    adminPermissions,
    getPermissionsByModule,
    checkIfCanEdit,
    checkIfCanView,
    canEditGroups,
    canEditAdministrators,
    canEditGeneralSettings,
    canEditCustomEmployeeCategory,
    canEditCompanies,
    canEditCompanyGroups,
    canViewDashboard,
    canEditEmployees,
    canEditBenefits,
    canEditNews,
    hasBenefitPermissions,
    hasEmployeePermissions,
    hasDashboardPermissions,
    hasAdministratorPermissions,
    hasGroupPermissions,
    hasNewsPermissions,
    hasOrganisationsPermissions,
    isRoot,
    hasNoCompanySettingsAccess,
    isMelpAdmin,
    isHrAdmin,
    updateMe: updateMe.execute,
    isFeatureEnabled,
  };
};

export const MeProvider = ({ children }: PropsWithChildren<{}>) => {
  const me = useProvideMe();
  return <meContext.Provider value={me}>{children}</meContext.Provider>;
};

export const useMe = (): UseMe => {
  return useContext(meContext);
};

export const useCompanyLanguages = () => {
  const { me } = useMe();

  const defaultLanguage = me?.parentCompany.settings.defaultLanguage ?? 'EN';
  const supportedLanguages = me?.parentCompany.supportedLanguages ?? [];

  return {
    defaultLanguage,
    supportedLanguages: [
      defaultLanguage,
      ...supportedLanguages
        .filter((language) => language !== defaultLanguage)
        .sort(),
    ],
    country: me?.parentCompany.country,
  };
};

const useAdministrators = (
  id?: string,
  query?: string,
  disableAPI?: boolean,
): UseAdministrators => {
  const { t } = useTranslation();
  const history = useHistory();
  const { parseAdministratorRequest } = useAdminUtils();
  const { data: administratorList, error: administratorListError } = useSWR(
    !disableAPI && !id
      ? `${Endpoints.admin}${!!query ? `?${query}` : ''}`
      : null,
  );
  const { data: administrator, error: administratorError } = useSWR(
    !disableAPI && !!id ? `${Endpoints.admin}/${id}` : null,
  );

  const { stopLoading, startLoading, loading } = useLoading();

  const parsedAdministratorList = useMemo(
    () =>
      administratorList?.data?.map((data: AdministratorListItem) => ({
        ...data,
        inactive: data.status === 'inactive',
      })) ?? [],
    [administratorList],
  );

  const parsedAdministratorTotals = useMemo(() => {
    const initialTotals = {
      fullName: '',
      email: '',
      phone: '',
      status: '',
    };
    if (!administratorList?.footer) return initialTotals;
    const { fullName, email, phone, status } = administratorList.footer;
    return {
      fullName: t('totals.administrator', { count: Number(fullName) }),
      email: t('totals.email', { count: Number(email) }),
      phone: t('totals.phone_number', { count: Number(phone) }),
      status: t('totals.inactive', {
        count: Number(status),
      }),
    };
  }, [administratorList, t]);

  const apiLoading = useMemo(() => {
    if (!!id) return !administrator && !administratorError;
    return !administratorList && !administratorListError;
  }, [
    administrator,
    administratorError,
    administratorList,
    administratorListError,
    id,
  ]);

  const isProgrammaticHr = useMemo(
    () => administrator?.role === 'hrProgrammatic',
    [administrator?.role],
  );

  const createAdministrator = useCallback(
    async (data: Partial<Administrator>) => {
      startLoading();
      const parsedData = parseAdministratorRequest(data);
      await makeRequest('post', `${Endpoints.createAdmin}`, parsedData)
        .then((res) => {
          if (!!res?.data?.id) {
            history.push(
              `${ROUTES.administrators.details.replace(
                ':id',
                res.data.id,
              )}?tab=administratorsPermissions`,
            );
          }
          toast(t('common.added_succesfully'), { type: 'success' });
          mutate(Endpoints.admin);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [history, parseAdministratorRequest, startLoading, stopLoading, t],
  );

  const updateAdministrator = useCallback(
    async (data: Partial<Administrator>, redirect?: boolean) => {
      const parsedData = parseAdministratorRequest(data);
      await makeRequest('patch', `${Endpoints.admin}/${id}`, parsedData)
        .then(() => {
          toast(t('common.updated_succesfully'), { type: 'success' });
          mutate(Endpoints.admin);
          mutate(Endpoints.me);
          mutate(`${Endpoints.admin}/${id}`);
          if (redirect !== false) {
            history.push(
              '/more/company-settings?tab=companySettingsAdministrators',
            );
          }
        })
        .finally(() => {
          stopLoading();
        });
    },
    [history, id, parseAdministratorRequest, stopLoading, t],
  );

  const createAdministratorIntegration = useCallback(
    async (data: Partial<Administrator>) => {
      startLoading();
      const firstName = data?.firstName ?? '';
      if (!!firstName) {
        startLoading();
        const resData = await makeRequest(
          'post',
          Endpoints.createProgrammatic,
          {
            firstName,
          },
        )
          .then((res) => {
            toast(t('common.added_succesfully'), { type: 'success' });
            mutate(Endpoints.admin);
            return res.data;
          })
          .finally(() => {
            stopLoading();
          });
        return resData;
      }
      return {};
    },
    [startLoading, stopLoading, t],
  );

  const resendInvite = useCallback(
    (id: string) => {
      startLoading();
      makeRequest('post', `${Endpoints.admin}/${id}/resendInvite`)
        .then(() => {
          toast(t('common.invitation_sent'), { type: 'success' });
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t],
  );

  const deleteAdmin = useCallback(() => {
    startLoading();
    makeRequest('delete', `${Endpoints.deleteHrAdmin}/${id}`)
      .then(() => {
        toast(t('common.deleted_succesfully'), { type: 'success' });
        history.push(
          '/more/company-settings?tab=companySettingsAdministrators',
        );
      })
      .finally(() => {
        stopLoading();
      });
  }, [history, id, startLoading, stopLoading, t]);

  return {
    resendInvite,
    createAdministrator,
    updateAdministrator,
    administrator,
    administratorList,
    loading: loading || apiLoading,
    parsedAdministratorList,
    parsedAdministratorTotals,
    createAdministratorIntegration,
    isProgrammaticHr,
    deleteAdmin,
  };
};

export default useAdministrators;

export const useAdministratorsAsyncMethods = (): Record<
  string,
  MpAsyncGetMethod
> => {
  const baseUrl = useMemo(() => `${Endpoints.adminFilterValues}`, []);

  const getFilterItems = useCallback(
    (filterName: AdministratorFilterNames) => {
      const apiUrl = `${baseUrl}/${filterName}`;
      return makeRequest('get', apiUrl).then(
        (res: AxiosResponse<string[]>) => res.data,
      );
    },
    [baseUrl],
  );

  const getFullNames = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(AdministratorFilterNames.FULL_NAMES),
    [getFilterItems],
  );

  const getEmail = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(AdministratorFilterNames.EMAIL),
    [getFilterItems],
  );

  const getPhone = useCallback<MpAsyncGetMethod>(
    () => getFilterItems(AdministratorFilterNames.PHONE),
    [getFilterItems],
  );

  return {
    getFullNames,
    getEmail,
    getPhone,
  };
};
