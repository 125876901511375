import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import {
  DateTimeInput,
  Modal,
  NumberInput,
  TextField,
} from 'melp-design/components';
import { useAdminClientUpdateDeposit } from 'store/admin-client-deposits';
import { ModalProps } from 'store/modal';
import { Stack } from '@mui/material';
import { useAdminClient } from 'store/admin-clients';
import { usePredefinedToasts } from 'utils/Toast';
import { fromCents, toCents } from 'utils/general';

interface Values {
  date: string;
  amount: number;
  comment: string;
  invoice: string;
}

interface Props extends ModalProps {
  depositId: string;
  clientId: string;
  initialValues: Values;
}

export const EditDepositModal: FC<Props> = ({
  depositId,
  clientId,
  initialValues,
  closeModal,
}) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const { control, handleSubmit } = useForm<Values>({
    defaultValues: {
      ...initialValues,
      amount: fromCents(initialValues.amount),
    },
  });

  const { data: client } = useAdminClient(clientId);

  const { mutate: updateDeposit, isLoading: isUpdating } =
    useAdminClientUpdateDeposit();

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateDeposit(
      {
        depositId,
        data: {
          amount: toCents(values.amount),
          currency: client?.defaultCurrency,
          invoice: values.invoice,
          comment: values.comment,
          date: values.date,
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
          closeModal({ action: 'CLOSE' });
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('expenses.filters.type.options.deposit')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.confirm'),
            type: 'submit',
            disabled: isUpdating || !client,
          },
        ]}
      >
        <Stack gap={2}>
          <Controller
            name="date"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <DateTimeInput
                label={t('common.date')}
                name={name}
                value={value}
                onChange={onChange}
                required
                ref={ref}
              />
            )}
          />

          <Controller
            name="amount"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <NumberInput
                label={t('employees.amount')}
                name={name}
                value={value}
                onChange={onChange}
                required
                key={value}
                ref={ref}
                InputProps={{
                  endAdornment: client?.defaultCurrency,
                }}
              />
            )}
          />

          <Controller
            name="invoice"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('common.invoice_number.label')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  required
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="comment"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('clients.comment')}
                  multiline
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              );
            }}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
