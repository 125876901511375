import { FC } from 'react';
import { AdminBrandCardContent } from 'containers/admin';
import { AdminBrandCardLayout } from 'pages/atoms';

const AdminBrandCardContentPage: FC = () => {
  return (
    <AdminBrandCardLayout>
      <AdminBrandCardContent />
    </AdminBrandCardLayout>
  );
};

export default AdminBrandCardContentPage;
