import { Colors } from 'melp-design/style';
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { APP } from 'config';
import { LIST_TOOLBAR_ID } from './ListPageHeader.constants';

interface Props {
  children: ReactNode;
}

const PageToolbar = (props: Props) => {
  return (
    <Box
      id={LIST_TOOLBAR_ID}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        margin: '0 -100%',
        padding: '0 100%',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(20px)',
        borderBottom: `1px solid ${Colors.elementsGrey}`,
      }}
    >
      <Box
        sx={{
          padding: '20px 30px',
          width: '100%',
          maxWidth: { lg: APP.layout.container.maxWidth },
          paddingLeft: { lg: 0 },
          paddingRight: { lg: 0 },
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default PageToolbar;
