import { z } from 'zod';
import { paginationFiltersSchema, sortFiltersSchema } from 'utils/schemas';
import { adminBrandProviders } from 'store/admin-brands';
import {
  adminBrandCardStatuses,
  adminBrandCardPriceTypes,
} from './admin-brand-cards.types';

export const adminBrandCardsFiltersSchema = z
  .object({
    search: z.string().catch(''),
    status: z.array(z.enum(adminBrandCardStatuses)).catch([]),
    brands: z.array(z.string()).catch([]),
    countries: z.array(z.string()).catch([]),
    providers: z.array(z.enum(adminBrandProviders)).catch([]),
    priceType: z.array(z.enum(adminBrandCardPriceTypes)).catch([]),
  })
  .merge(sortFiltersSchema)
  .merge(paginationFiltersSchema);
