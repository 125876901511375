import { FC } from 'react';
import { Modal, Typography } from 'melp-design/components';
import { Trans, useTranslation } from 'react-i18next';
import { ModalProps, useModalContext } from 'store/modal';
import { Box, List, ListItem, Stack } from '@mui/material';
import { SystemColors } from 'melp-design/style';
import { clipboard } from 'utils/general';
import { BrandCardsBulkUpdateImportModal } from '..';

interface Props extends ModalProps {
  errors: Array<{ row: number; field: string; message: string }>;
}

export const BrandCardsBulkUpdateErrorsModal: FC<Props> = ({
  closeModal,
  errors,
}) => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  return (
    <Modal.Content
      title={t('global.actions.bulk_update')}
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('common.cancel'),
        },
        {
          variant: 'tertiary',
          onClick: () => {
            clipboard.copy(
              errors
                .map((error) =>
                  t('recognitions.import.errors.error', {
                    row: error.row,
                    field: error.field || '-',
                    message: error.message,
                  }),
                )
                .join('\r\n')
                .replaceAll(/<\/?\d+>/g, ''),
            );
          },
          label: t('global.actions.copy_to_clipboard'),
        },
        {
          variant: 'primary',
          onClick: () => {
            closeModal({ action: 'CLOSE' });
            openModal({
              component: BrandCardsBulkUpdateImportModal,
            });
          },
          label: t('common.back'),
        },
      ]}
    >
      <Stack gap={1}>
        <Typography color="error">
          {t('recognitions.import.errors.description')}
        </Typography>

        <List>
          {errors.map((error, i) => (
            <ListItem sx={{ px: 0 }} key={i}>
              <Typography variant="p2">
                <Trans
                  i18nKey="recognitions.import.errors.error"
                  values={{
                    row: error.row,
                    field: error.field || '-',
                    message: error.message,
                  }}
                  components={[
                    <Box
                      component="span"
                      sx={{ color: SystemColors.danger.base }}
                      key="0"
                    />,
                  ]}
                />
              </Typography>
            </ListItem>
          ))}
        </List>
      </Stack>
    </Modal.Content>
  );
};
