import { BackButton, Typography } from 'melp-design/components';
import { Colors } from 'melp-design/style';
import { Box, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { APP } from 'config';

interface Props {
  title: string;
  sideContent?: ReactNode;
  last?: boolean;
  first?: boolean;
  backTo?: string;
}

const PageTitleBar = ({ first, last, title, sideContent, backTo }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginX: '-100%',
        padding: '0 100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(20px)',
        marginTop: first ? '-30px' : undefined,
        borderBottom: last ? `1px solid ${Colors.elementsGrey}` : undefined,
        marginBottom: last ? '30px' : undefined,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="20px"
        sx={{
          padding: '0 30px 30px',
          paddingTop: first ? '30px' : undefined,
          width: '100%',
          maxWidth: { lg: APP.layout.container.maxWidth },
          paddingLeft: { lg: 0 },
          paddingRight: { lg: 0 },
          borderBottom: last ? 'none' : `1px solid ${Colors.elementsGrey}`,
        }}
      >
        <Stack flexDirection="row" alignItems="center" gap={2}>
          {backTo ? <BackButton to={backTo} /> : null}
          <Typography variant="h2">{title}</Typography>
        </Stack>
        <div>{sideContent}</div>
      </Stack>
    </Box>
  );
};

export default PageTitleBar;
