import { FC } from 'react';
import { AdminBrandSettings } from 'containers/admin';
import { AdminBrandLayout } from 'pages/atoms';

const AdminBrandSettingsPage: FC = () => {
  return (
    <AdminBrandLayout>
      <AdminBrandSettings />
    </AdminBrandLayout>
  );
};

export default AdminBrandSettingsPage;
