import { Box, Stack } from '@mui/material';
import { APP, ROUTES } from 'config';
import {
  Button,
  DataLossPrompt,
  Footer,
  Loader,
  MultiLanguageInputContainer,
  NothingFoundAlert,
  NumberInput,
  Panel,
  SelectInput,
  SortableList,
  TextField,
} from 'melp-design/components';
import { FC, useEffect } from 'react';
import {
  Control,
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form-latest';
import { useTranslation } from 'react-i18next';
import { usePredefinedToasts } from 'utils/Toast';
import { useRouteParams } from 'utils/useIdParam';
import { fromCents, toCents, upper } from 'utils/general';
import {
  useAdminBrandCard,
  useAdminUpdateBrandCardContent,
} from 'store/admin-brand-cards';
import { Translations } from 'types/Common';
import { ContentLanguage, contentLanguages } from 'types/general';
import { formatLanguage } from 'utils/format';
import { SystemColors } from 'melp-design/style';
import { Delete } from '@mui/icons-material';

interface Values {
  languages: Array<Uppercase<ContentLanguage>>;
  translations: Translations<{
    description: string;
    terms: string;
    redeemInstructions: string;
  }>;
  prices: Array<{
    faceValue: number;
    content: Translations<{ faceValuesLabel: string }>;
  }>;
}

const INITIAL_VALUES: Values = {
  languages: [upper(APP.locales.default)],
  translations: [
    {
      language: upper(APP.locales.default),
      description: '',
      terms: '',
      redeemInstructions: '',
    },
  ],
  prices: [
    {
      faceValue: NaN,
      content: [{ language: upper(APP.locales.default), faceValuesLabel: '' }],
    },
  ],
};

const PriceTranslationsList: FC<{
  control: Control<Values>;
  priceIndex: number;
}> = ({ control, priceIndex }) => {
  const { t } = useTranslation();

  const { fields } = useFieldArray({
    name: `prices.${priceIndex}.content`,
    control,
  });

  return (
    <MultiLanguageInputContainer
      fields={fields.map((field, i) => ({
        id: field.id,
        language: field.language,
        component: (
          <Controller
            name={`prices.${priceIndex}.content.${i}.faceValuesLabel`}
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <TextField
                  label={t('shop.variant.title')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              );
            }}
          />
        ),
      }))}
    />
  );
};

export const AdminBrandCardContent: FC = () => {
  const { t, i18n } = useTranslation();
  const { id: cardId } = useRouteParams(ROUTES.admin.cards.content);
  const predefinedToasts = usePredefinedToasts();

  const { data: card, isLoading } = useAdminBrandCard(cardId);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields, errors },
  } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });
  const { fields: translationsFields, replace: replaceTranslations } =
    useFieldArray({ name: 'translations', control });
  const {
    fields: pricesFields,
    replace: replacePrices,
    append: appendPrice,
    move: movePrices,
    remove: removePrice,
  } = useFieldArray({
    name: 'prices',
    control,
  });

  useEffect(() => {
    if (card) {
      reset({
        languages: card.supportedLanguages,
        translations: card.content.map((trans) => ({
          ...trans,
          description: trans.description,
          terms: trans.terms,
          redeemInstructions: trans.redeemInstructions,
        })),
        prices: card.prices.map((price) => ({
          ...price,
          faceValue: fromCents(price.faceValue),
        })),
      });
    }
  }, [card, reset]);

  const { mutate: updateCardContent, isLoading: isUpdating } =
    useAdminUpdateBrandCardContent();

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateCardContent(
      {
        cardId,
        data: {
          translations: values.translations,
          prices: values.prices.map((price) => ({
            faceValue: toCents(price.faceValue),
            content: price.content,
          })),
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  if (isLoading) return <Loader />;
  if (!card) return <NothingFoundAlert />;

  const isDirty = !!Object.keys(dirtyFields).length;
  const [languages, translations, prices] = watch([
    'languages',
    'translations',
    'prices',
  ]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Panel title={t('benefitTemplates.templateContent')}>
            <Stack spacing={4}>
              <Controller
                name="languages"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <SelectInput
                    label={t('companies.supported_languages')}
                    name={name}
                    value={value}
                    onChange={(value) => {
                      const selectedLanguages = value as Array<
                        Uppercase<ContentLanguage>
                      >;
                      replaceTranslations(
                        selectedLanguages.map(
                          (language) =>
                            translations.find(
                              (t) => t.language === language,
                            ) ?? {
                              ...INITIAL_VALUES.translations[0],
                              language,
                            },
                        ),
                      );
                      replacePrices(
                        prices.map((price) => ({
                          faceValue: price.faceValue,
                          content: selectedLanguages.map(
                            (language) =>
                              price.content.find(
                                (t) => t.language === language,
                              ) ?? {
                                ...INITIAL_VALUES.prices[0].content[0],
                                language,
                              },
                          ),
                        })),
                      );
                      onChange(selectedLanguages);
                    }}
                    options={contentLanguages.map((l) => {
                      const language = l.toUpperCase();
                      return {
                        value: language,
                        label: formatLanguage(i18n.language, language),
                      };
                    })}
                    multiple
                    required
                    ref={ref}
                  />
                )}
              />

              {languages.length ? (
                <>
                  <MultiLanguageInputContainer
                    label={t('employees.details')}
                    fields={translationsFields.map((field, i) => ({
                      id: field.id,
                      language: field.language,
                      component: (
                        <Controller
                          name={`translations.${i}.description`}
                          control={control}
                          render={({
                            field: { name, value, onChange, ref },
                          }) => (
                            <TextField
                              name={name}
                              value={value}
                              onChange={onChange}
                              rows={5}
                              ref={ref}
                            />
                          )}
                        />
                      ),
                    }))}
                  />

                  <MultiLanguageInputContainer
                    label={t('employees.terms')}
                    info={t('common.max_char', { count: 2000 })}
                    fields={translationsFields.map((field, i) => ({
                      id: field.id,
                      language: field.language,
                      component: (
                        <Controller
                          name={`translations.${i}.terms`}
                          control={control}
                          rules={{
                            maxLength: {
                              value: 2000,
                              message: t('common.maxValueValidationError', {
                                max: 2000,
                              }),
                            },
                          }}
                          render={({
                            field: { name, value, onChange, ref },
                          }) => (
                            <TextField
                              name={name}
                              value={value}
                              onChange={onChange}
                              rows={5}
                              errorMessage={
                                errors.translations?.[i]?.terms?.message
                              }
                              ref={ref}
                            />
                          )}
                        />
                      ),
                    }))}
                  />

                  <MultiLanguageInputContainer
                    label={t('shop.vouchers.redeem.label')}
                    info={t('common.max_char', { count: 2000 })}
                    fields={translationsFields.map((field, i) => ({
                      id: field.id,
                      language: field.language,
                      component: (
                        <Controller
                          name={`translations.${i}.redeemInstructions`}
                          control={control}
                          rules={{
                            maxLength: {
                              value: 2000,
                              message: t('common.maxValueValidationError', {
                                max: 2000,
                              }),
                            },
                          }}
                          render={({
                            field: { name, value, onChange, ref },
                          }) => (
                            <TextField
                              name={name}
                              value={value}
                              onChange={onChange}
                              rows={5}
                              errorMessage={
                                errors.translations?.[i]?.redeemInstructions
                                  ?.message
                              }
                              ref={ref}
                            />
                          )}
                        />
                      ),
                    }))}
                  />
                </>
              ) : null}
            </Stack>
          </Panel>

          {card.priceType === 'fixed' ? (
            <Panel title={t('marketplace.ordersTablePrice')}>
              <Stack spacing={2}>
                <SortableList
                  key={JSON.stringify(pricesFields.map((field) => field.id))}
                  onPositionChange={movePrices}
                  disabled
                  items={pricesFields.map((field, i) => ({
                    id: field.id,
                    content: (
                      <Stack
                        direction="row"
                        spacing={2}
                        py={1}
                        px={2}
                        sx={{ backgroundColor: SystemColors.white }}
                        borderRadius={1}
                        boxShadow={` 0 0 0 1px ${SystemColors.grey[90]}`}
                        key={field.id}
                      >
                        <Box flex={1}>
                          <PriceTranslationsList
                            priceIndex={i}
                            control={control}
                          />
                        </Box>
                        <Box flex={0.3}>
                          <Controller
                            name={`prices.${i}.faceValue`}
                            control={control}
                            render={({
                              field: { name, value, onChange, ref },
                            }) => (
                              <NumberInput
                                label={t('marketplace.ordersTablePrice')}
                                name={name}
                                value={value}
                                onChange={onChange}
                                required
                                disabled={card.provider !== 'melp'}
                                key={value}
                                sx={{ width: '100%' }}
                                ref={ref}
                              />
                            )}
                          />
                        </Box>

                        <Box flexShrink={0}>
                          <Button
                            variant="neutral-outline"
                            size="md"
                            icon={Delete}
                            onClick={() => removePrice(i)}
                            disabled={
                              prices.length <= 1 || card.provider !== 'melp'
                            }
                            title={t('common.remove')}
                          />
                        </Box>
                      </Stack>
                    ),
                  }))}
                />

                <Box>
                  <Button
                    label={t('shop.actions.add_variant')}
                    variant="tertiary"
                    disabled={card.provider !== 'melp'}
                    onClick={() => {
                      appendPrice({
                        ...INITIAL_VALUES.prices[0],
                        content: languages.map((language) => ({
                          language,
                          faceValuesLabel: '',
                        })),
                      });
                    }}
                  />
                </Box>
              </Stack>
            </Panel>
          ) : null}
        </Stack>

        <Footer visible={isDirty}>
          <Stack direction="row" justifyContent="end" gap="10px">
            <Button
              label={t('common.cancel')}
              variant="neutral-outline"
              onClick={() => {
                reset(undefined, { keepDirty: false });
              }}
            />
            <Button
              label={t('common.save')}
              type="submit"
              disabled={isUpdating}
            />
          </Stack>
        </Footer>
      </form>

      <DataLossPrompt when={isDirty && !isUpdating} />
    </>
  );
};
