import { Box } from '@mui/material';
import { APP } from 'config';
import { ReactNode } from 'react';

interface Props {
  /**
   * Content
   */
  children: ReactNode;
}

const PageTop = (props: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginX: '-100%',
        marginTop: '-30px',
        marginBottom: '0px',
        padding: '0 100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(20px)',
      }}
    >
      <Box
        sx={{
          padding: '20px 30px',
          width: '100%',
          maxWidth: { lg: APP.layout.container.maxWidth },
          paddingLeft: { lg: 0 },
          paddingRight: { lg: 0 },
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default PageTop;
